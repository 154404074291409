@charset "UTF-8";

	html,
	body { -webkit-text-size-adjust:100%; height:100%; position:relative; width:100%; }
	body.notouch { overflow:hidden !important; touch-action:none; }
	body.touch_y { touch-action:pan-y; }
	body.extend { height:calc(100vh + 350px); }

	body.gbg { background:#f0f0f0; }

	.adv_accum_bnr video { width:100%; }
	/*video::-webkit-media-controls {display: none;}
	video::-webkit-media-controls-play-button {}
	video::-webkit-media-controls-volume-slider {}
	video::-webkit-media-controls-mute-button {}
	video::-webkit-media-controls-timeline {}
	video::-webkit-media-controls-current-time-display {}*/

	/*section#body { height:100%; position:relative; }*/
	section#body { bottom:0; height:100%; left:0; position:absolute; right:0; top:0; width:100%; }
	section#body > span { display:block; position:relative; height:100%; }

	#wrap { height:100%; padding-top:96px; position:relative; width:100%; }

	/* header */
	header { background:#fff; }
	#header { background:#ffc000; border-bottom:1px solid #c6c6c6; height:97px; left:0; position:fixed; top:0; width:100%; z-index:50; }
	#header h1 { height:52px; left:50px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:220px; }
	#header h1 a { background:url("../img/common/logo.png") no-repeat; background-size:100% auto; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }

	.header_alarm { height:41px; position:absolute; right:31px; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:32px; }
	.header_alarm a { background:url("../img/common/icon_alarm.png") no-repeat; background-size:100% auto; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }
	.new_alarm { background:#dc4630; border-radius:50%; display:block; height:11px; position:absolute; right:-7px; top:-2px; width:11px; }
	/* header end */

	/* content */
	#content { overflow:hidden; padding:92px 0 129px; position:relative; }

	nav.common_nav { background:#fff; border-bottom:1px solid #c6c6c6; height:91px; left:0; position:fixed; top:97px; width:100%; z-index:10; }
	nav.common_nav ul { height:90px; }
	nav.common_nav ul:after { clear:both; content:""; display:block; }
	nav.common_nav ul li { float:left; height:90px; width:188px; }
	nav.common_nav ul li a { color:#333; display:block; font-size:32px; font-weight:400; height:100%; line-height:90px; position:relative; text-align:center; width:100%; }
	nav.common_nav ul li a.on { color:#f0af24; }
	nav.common_nav ul li a.on:after { background:#f0af24; bottom:0; content:""; height:5px; left:0; position:absolute; width:100%; }
	/* edge start */
	nav.common_nav div { height:90px; }
	nav.common_nav div:after { clear:both; content:""; display:block; }
	nav.common_nav div p { float:left; height:90px !important; width:188px; }
	nav.common_nav div p a { color:#333; display:block; font-size:32px; font-weight:400; height:100%; line-height:1; padding:28px 0; position:relative; text-align:center; width:100%; }
	nav.common_nav div p a.on { color:#f0af24; }
	nav.common_nav div p a.on:after { background:#f0af24; bottom:0; content:""; height:5px; left:0; position:absolute; width:100%; }
	/* edge end */
	/* content end */

	/* gnb */
	#gnb { background:#fff; bottom:-1px; box-shadow:0 0 15px rgba(136, 135, 132, 0.3); height:105px; left:0; position:fixed; width:100%; z-index:50; }
	#gnb ul:after { clear:both; content:""; display:block; }
	#gnb ul li { float:left; height:104px; width:25%; }
	#gnb ul li a { color:#333; display:block; font-size:20px; font-weight:400; height:100%; padding-top:70px; text-align:center; width:100%; }
	#gnb ul li a.on { color:#f0af24; }
	#gnb ul li a.gnb01 { background:url("../img/common/gnb_menu01.png") center 17px no-repeat; }
	#gnb ul li a.gnb01.on { background:url("../img/common/gnb_menu01_on.png") center 17px no-repeat; }
	#gnb ul li a.gnb02 { background:url("../img/common/gnb_menu02.png") center 19px no-repeat; }
	#gnb ul li a.gnb02.on { background:url("../img/common/gnb_menu02_on.png") center 19px no-repeat; }
	#gnb ul li a.gnb03 { background:url("../img/common/gnb_menu03.png") center 24px no-repeat; }
	#gnb ul li a.gnb03.on { background:url("../img/common/gnb_menu03_on.png") center 24px no-repeat; }
	#gnb ul li a.gnb04 { background:url("../img/common/gnb_menu04.png") center 16px no-repeat; }
	#gnb ul li a.gnb04.on { background:url("../img/common/gnb_menu04_on.png") center 16px no-repeat; }
	/* gnb end */

	.layer_dimm { background:#333; height:100%; left:0; opacity:.8; position:fixed; top:0; width:100%; z-index:150; display:none; }
	.white_dimm { background:#fff; height:100%; left:0; position:fixed; top:0; width:100%; z-index:150; display:none; }
	.layer_dimm.prod_detail_end { z-index:999; }

	.btm_btn { bottom:0; height:100px; left:0; position:fixed; width:100%; z-index:50; }
	.btm_btn a { background:#c6c6c6; color:#fff; display:block; font-size:32px; font-weight:500; height:100%; line-height:100px; text-align:center; width:100%; }
	.btm_btn a.on { background:#333; }

	.btm_btn a span.yellow { color:#f0af24; }
	.btm_btn a b { font-weight:700; }
	.btm_btn a img { margin:40px 0 0 22px; }

	.common_pop { background:#fff; border-radius:30px; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:85%; z-index:151; display:none;}
	.common_pop_txt { height:274px; position:relative; }
	.common_pop_txt p { color:#333; font-size:28px; font-weight:400; left:50%; line-height:42px; padding:0 30px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); white-space:nowrap; }
	.common_pop_txt p.emphasis { background:url("../img/mem/icon_time_limit.png") left 7px no-repeat; padding-left:38px; }
	.common_pop a.pop_ok { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; bottom:0; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; left:0; line-height:100px; position:absolute; text-align:center; width:100%; }

	.common_pop_txt p strong { font-weight:700; }
	.common_pop_txt p span { color:#666; font-size:26px; }

	.common_pop.h523 { height:523px; }
	.common_pop.h723 { height:723px; }
	.common_pop_txt.h423 { height:423px; }
	.common_pop_txt.h623 { height:623px; }

	.common_pop.existing_id { -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }
	.common_pop.saving { -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }

	.close_pop { background:url("../img/common/btn_close.png") no-repeat; display:block; height:33px; overflow:hidden; position:absolute; right:30px; text-indent:-20000px; top:36px; width:33px; z-index:5; }

	.common_pop_txt p.login_limit_warn { background:url("../img/common/icon_dvc_warn.png") center top no-repeat; font-size:28px; font-weight:500; left:50%; line-height:46px; padding-top:135px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); white-space:nowrap; }
	.common_pop_txt p.login_limit_warn strong { font-weight:700; }

	.btm_confirm_btns { border-bottom-left-radius:30px; border-bottom-right-radius:30px; bottom:0; height:100px; position:absolute; width:100%; }
	.btm_confirm_btns:after { clear:both; content:""; display:block; }
	.btm_confirm_btns a { float:left; font-size:28px; font-weight:500; height:100px; line-height:100px; text-align:center; width:50%; }
	.btm_confirm_btns a.white { background:#fff; border-bottom-left-radius:30px; border-top:1px solid #333; color:#666; }
	.btm_confirm_btns a.dgrey { background:#333; border-bottom-right-radius:30px; color:#fff; }

	.network_error_pop { background:#fff; border-radius:30px; display:none; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:85%; z-index:151; }
	.server_close_pop { background:#fff; border-radius:30px; display:none; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:85%; z-index:151; }
	.server_close_pop a.pop_ok { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; bottom:0; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; left:0; line-height:100px; position:absolute; text-align:center; width:100%; }
	.update_pop { background:#fff; border-radius:30px; display:none; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:85%; z-index:151; }
	.update_pop a.pop_ok { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; bottom:0; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; left:0; line-height:100px; position:absolute; text-align:center; width:100%; }

	.exst_id_warn { background:url("../img/common/icon_stop.png") center top no-repeat; color:#333; font-size:28px; font-weight:500; left:50%; line-height:46px; padding-top:150px; position:absolute; text-align:center; top:105px; -webkit-transform:translateX(-50%); transform:translateX(-50%); white-space:nowrap; }
	.exst_id_warn.sleep { padding-top:120px; top:75px; }

	.orange { color:#dc4630; }

	.btn_pop_inquiry { background:url("../img/common/icon_letter.png") 23px center no-repeat #f0f0f0; border:2px solid #e3e3e3; border-radius:10px; color:#666; display:block; font-size:24px; font-weight:500; height:55px; line-height:49px; margin:0 auto; padding-left:73px; text-align:left; width:206px; }
	.btn_pop_inquiry img { margin:17px 0 0 12px; }

	.login_penalty { background:url("../img/common/icon_stop.png") center top no-repeat; color:#333; font-size:28px; font-weight:500; left:50%; line-height:46px; padding:109px 45px 0; position:absolute; text-align:center; top:40px; -webkit-transform:translateX(-50%); transform:translateX(-50%); white-space:nowrap; width:100%; }
	.login_penalty .terms { background:#e4e4e4; color:#333; font-size:28px; font-weight:500; height:130px; line-height:46px; margin-bottom:10px; padding:20px 55px; text-align:left; }

	.common_alarm_list ul li { border-bottom:1px solid #f0f0f0; height:171px; position:relative; }
	.common_alarm_list ul li a { display:block; height:100%; position:relative; width:100%; }
	.common_alarm_list ul li a .alarm_list_txt { left:144px; padding-right:55px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.common_alarm_list ul li a .alarm_list_txt span { color:#666; display:block; font-size:26px; font-weight:400; line-height:1; }
	.common_alarm_list ul li a .alarm_list_txt p { color:#333; font-size:28px; font-weight:400; line-height:36px; margin-top:16px; }
	.common_alarm_list ul li i { background:#f0af24; border-radius:50%; display:block; height:11px; left:105px; position:absolute; top:49px; width:11px; }

	.common_alarm_list ul li a.icon_coup_exprd { background:url("../img/common/icon_coup_exprd.png") 31px center no-repeat; }
	.common_alarm_list ul li a.icon_save { background:url("../img/common/icon_save.png") 32px center no-repeat; }
	.common_alarm_list ul li a.icon_accum_deny { background:url("../img/common/icon_accum_deny.png") 31px center no-repeat; }
	.common_alarm_list ul li a.icon_alarm_noti { background:url("../img/common/icon_alarm_noti.png") 30px center no-repeat; }
	.common_alarm_list ul li a.icon_reward_chk { background:url("../img/common/icon_reward_chk.png") 31px center no-repeat; }
	.common_alarm_list ul li a.icon_coup { background:url("../img/common/icon_coup.png") 31px center no-repeat; }
	.common_alarm_list ul li a.icon_url_link { background:url("../img/common/icon_url_link.png") 33px center no-repeat; }
	.common_alarm_list ul li a.icon_cash_out { background:url("../img/common/icon_cash_out.png") 31px center no-repeat; }
	.common_alarm_list ul li a.icon_point { background:url("../img/common/icon_point.png") 30px center no-repeat; }

	.common_alarm_list ul li a > img { height:110px; left:20px; position:absolute; top:30px; width:110px; }

	.alarm_detail_top_link { background:#fff; border-bottom:1px solid #c6c6c6; height:91px; margin-bottom:18px; }
	.alarm_detail_top_link a { background:url("../img/mem/btn_arrow_g.png") right 30px center no-repeat; color:#333; display:block; font-size:26px; font-weight:500; height:100%; line-height:90px; overflow:hidden; padding:0 72px 0 30px; text-overflow:ellipsis; white-space:nowrap; width:100%; }

	.alarm_date_info { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; height:122px; position:relative; }
	.alarm_date_info dt,
	.alarm_date_info dd { font-size:32px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.alarm_date_info dt { color:#666; font-family:"Roboto"; font-weight:400; left:47px; }
	.alarm_date_info dd { color:#333; font-weight:700; right:47px; }

	.perch_list_wrap { background:#fff; border-top:1px solid #c6c6c6; position:relative; }

	.mart_logo_wrap { border-bottom:1px solid #f0f0f0; height:91px; overflow:hidden; position:relative; }
	.mart_logo_wrap img.logo { height:60px; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:auto !important; }

	.perch_list_cont { padding:40px 0 60px; }
	.perch_list_warn { background:url("../img/common/icon_warn.png") 18px center no-repeat; border:2px solid #dc4630; color:#dc4630; font-size:26px; font-weight:400; height:80px; line-height:76px; margin:0 30px; padding:0 18px 0 58px; }
	.perch_confirm { background:url("../img/common/icon_ok.png") 18px center no-repeat; border:2px solid #333; color:#333; font-size:26px; font-weight:400; height:80px; line-height:76px; margin:0 30px; padding:0 18px 0 58px; }

	.perch_list_cont ul li { border-bottom:1px solid #f0f0f0; height:171px; position:relative; }
	.perch_list_cont ul li:last-child { border-bottom:none; }
	.perch_list_cont ul li > * { position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.perch_list_cont ul li img { left:30px; width:90px; }
	.perch_list_cont ul li p.prod_name { -webkit-box-orient:vertical; color:#333; display:-webkit-box; font-size:28px; font-weight:400; left:140px; -webkit-line-clamp:2; overflow:hidden; text-overflow:ellipsis; width:490px; }
	.perch_list_cont ul li p.prod_point { color:#dc4630; font-family:"Roboto"; font-size:32px; font-weight:700; right:30px; }

	.prod_point_sum { padding:0 30px; }
	.prod_point_sum .inner { border-top:2px solid #666; height:88px; position:relative; }
	.prod_point_sum .inner > * { color:#333; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.prod_point_sum .inner p.count { font-size:28px; font-weight:500; left:130px; }
	.prod_point_sum .inner strong.result { font-family:"Roboto"; font-size:32px; font-weight:700; right:0; }

	.receipt_pic_wrap { padding:80px 28px 100px; }
	.receipt_pic_wrap img { width:100%; }

	.prod_detail_popttl { border-bottom:1px solid #c6c6c6; color:#333; display:block; font-size:32px; font-weight:500; height:91px; line-height:90px; text-align:center; }
	.prod_detail_pop { background:#fff; border-radius:30px; height:auto; left:50%; padding-bottom:100px; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:640px; z-index:151; }
	.prod_detail_pop.fixed { position:fixed; top:50% !important; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }
	.prod_detail_pop_cont { padding:30px 20px 65px; }
	.prod_detail_pop_cont p { color:#666; font-size:32px; font-weight:400; margin-top:60px; text-align:center; }

	.prod_detail_pop.prod_detail_pop_end { z-index:1000; }

	.prod_detail_pop_cont.ios { padding:65px 20px; }
	.prod_detail_pop_cont.ios p { color:#333; font-size:28px; margin-top:0; }

	.sub_cont_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:700; height:91px; line-height:90px; padding:0 30px; position:relative; text-align:left; }

	.full_layer_bg { background:#fff; height:100%; left:0; position:fixed; top:0; width:100%; z-index:97; }
	.full_layer { background:#f0f0f0; height:100vh; position:relative; z-index:98; }

	.grey_gap { background:#f0f0f0; height:20px; position:relative; }

	.pop_sns_share { background:#fff; border-radius:30px; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:640px; z-index:151; }
	.pop_sns_share_ttl { border-bottom:1px solid #c6c6c6; color:#333; display:block; font-size:32px; font-weight:500; height:81px; line-height:80px; text-align:center; }
	.pop_sns_share_btns { padding-top:52px; }
	.pop_sns_share_btns ul { font-size:0; text-align:center; }
	.pop_sns_share_btns ul li { display:inline-block; height:90px; margin:0 15px; vertical-align:top; width:90px; }
	.pop_sns_share_btns ul li a { display:block; height:100%; width:100%; }
	.pop_sns_share a.pop_ok { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; bottom:0; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; left:0; line-height:96px; position:absolute; text-align:center; width:100%; }

	.tutorial_slide { background:#f7f9fa; height:100%; position:relative; }
	.tutorial_slide > .swiper-container { height:100%; }

	.tutorial_slide .swiper-slide img { bottom:0; position:absolute; width:100%; }

	.tutorial_slide .swiper-container-horizontal > .swiper-pagination-bullets { bottom:70px; position:absolute; text-align:center; z-index:5; }
	.tutorial_slide .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { background:#e1e1e1; height:14px; margin:0 9px; opacity:1; width:14px; }
	.tutorial_slide .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active { background:#d45a31; }

	.btn_tuto_skip { bottom:70px; color:#777; display:inline-block; font-size:22px; position:absolute; right:45px; z-index:10; }

	.tutorial_slide .swiper-slide a { bottom:230px; display:block; height:240px; left:50%; margin-left:-200px; overflow:hidden; position:absolute; text-indent:-20000px; width:400px; z-index:5; }

	.spinner { height:118px; left:50%; margin:-59px 0 0 -59px; position:absolute; top:50%; width:118px; z-index:160; }

	/* settings */
	input::-webkit-input-placeholder { color:#c6c6c6; }

	.mb20 { margin-bottom:20px !important; }

	.mt20 { margin-top:20px !important; }
	.mt80 { margin-top:80px !important; }
	.mt116 { margin-top:116px !important; }
	.mt140 { margin-top:140px !important; }

	.pt0 { padding-top:0 !important; }
	.pt50 { padding-top:50px !important; }
	.pt65 { padding-top:65px !important; }
	.pt100 { padding-top:100px !important; }

	.tal { text-align:left !important; }

	.w105 { width:105px !important; }
	.w130 { width:130px !important; }
	.w170 { width:170px !important; }
	.w345 { width:345px !important; }
	.w466 { width:466px !important; }