@charset "UTF-8";

	.main_cont_wrap { margin-top:20px; overflow:hidden; }
	.main_cont_wrap.sect_prod { margin-top:0; padding-bottom:5px; }
	.main_cont_wrap > h2.main_cont_ttl { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; color:#333; font-size:32px; font-weight:700; height:92px; line-height:1; overflow:hidden; padding:28px 120px; position:relative; text-align:center; text-overflow:ellipsis; white-space:nowrap; }
	.main_cont_wrap > h2.main_cont_ttl a.about_ranking { background:url("../img/main/icon_ques.png") no-repeat; display:block; height:40px; margin-top:-20px; overflow:hidden; position:absolute; right:30px; text-indent:-20000px; top:50%; width:40px; }

	.main_cont_wrap > h2.main_cont_ttl p.rank_stand_date { bottom:15px; color:#333; font-size:20px; font-weight:400; line-height:1; position:absolute; right:30px; }

	.main_cont_slide .swiper-wrapper.important { height:auto !important; }

	.main_cont_inner { padding:20px 30px 0 30px; }

	.main_cont_wrap .main_cont_inner h2.main_cont_ttl { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; color:#333; font-size:32px; font-weight:700; height:92px; line-height:86px; margin:0 0 20px -30px; position:relative; text-align:center; width:750px; }

	.main_rank_cate_wrap { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); height:249px; overflow:hidden; padding:18px 21px; position:relative; }
	.main_rank_cate_ttl { border-bottom:1px solid #c6c6c6; height:32px; position:relative; }
	.main_rank_cate_ttl strong { color:#333; display:block; font-size:22px; font-weight:300; left:20px; line-height:1; position:absolute; top:0; }

	.main_rank_cate_list { margin-top:18px; }
	.main_rank_cate_list ol { font-size:0; text-align:center; }
	.main_rank_cate_list ol li { display:inline-block; height:170px; margin:0 20px; vertical-align:top; white-space:nowrap; width:170px; }
	.main_rank_cate_list ol li a { display:block; height:100%; padding-top:140px; position:relative; width:100%; }
	.main_rank_cate_list ol li figure { height:120px; left:50%; position:absolute; top:0; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:120px; }
	.main_rank_cate_list ol li figure i { background:url("../img/common/badge_k.png") no-repeat; background-size:100% auto; color:#fff; display:block; font-size:22px; font-style:normal; font-weight:700; height:54px; left:-22px; line-height:54px; position:absolute; text-align:center; top:-8px; width:54px; z-index:1; }
	.main_rank_cate_list ol li figure i.win { background:url("../img/common/badge_y.png") no-repeat; background-size:100% auto; }
	.main_rank_cate_list ol li figure img { border-radius:50%; }
	.main_rank_cate_list ol li p { color:#333; font-size:22px; font-weight:400; left:50%; line-height:1; position:absolute; text-align:center; -webkit-transform:translateX(-50%); transform:translateX(-50%); }

	.main_mid_bnr { background:#fff; margin-top:20px; overflow:hidden; position:relative; }

	.main_mid_bnr .swiper-pagination-fraction,
	.main_mid_bnr .swiper-pagination-custom,
	.main_mid_bnr.swiper-container-horizontal > .swiper-pagination-bullets { bottom:4px; }
	.main_mid_bnr .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin:0 3px; }
	.main_mid_bnr .swiper-pagination-bullet { background:#333; height:10px; opacity:.5; width:10px; }
	.main_mid_bnr .swiper-pagination-bullet-active { opacity:.88; }

	.recom_prod_list { height:276px; }
	.recom_prod_list ul:after { clear:both; content:""; display:block; }
	.recom_prod_list ul li { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); float:left; height:268px; overflow:hidden; position:relative; width:223px; }
	.recom_prod_list ul li a { display:block; height:100%; padding:150px 7px 0; position:relative; width:100%; }
	.recom_prod_list ul li figure { height:140px; left:50%; position:absolute; top:5px; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:140px; }
	.recom_prod_list ul li strong.point { color:#dc4630; display:block; font-family:"Roboto", "Noto Sans KR", HelveticaNeue, DroidSans, Sans-serif, Helvetica; font-size:34px; font-weight:700; line-height:1; text-align:left; }
	.recom_prod_list ul li p.name { color:#333; font-size:20px; font-weight:400; line-height:1; margin-top:3px; text-align:left; }

	.recom_prod_list ul li .stat_btns { height:34px; margin-top:14px; }
	.recom_prod_list ul li .stat_btns:after { clear:both; content:""; display:block; }
	.recom_prod_list ul li .stat_btns span { background:#fff; border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:20px; font-weight:400; height:34px; margin-left:3px; letter-spacing:-1px; line-height:32px; text-align:center; width:102px; }
	.recom_prod_list ul li .stat_btns span:first-child { margin-left:0; }
	/* edge start */
	.recom_prod_list div.swiper-container { overflow:visible; }
	.recom_prod_list div.swiper-wrapper:after { clear:both; content:""; display:block; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); float:left; height:268px; margin-right:5px; overflow:hidden; position:relative; width:223px; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide a { display:block; height:100%; padding:150px 7px 0; position:relative; width:100%; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide figure { height:140px; left:50%; position:absolute; top:5px; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:140px; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide strong.point { color:#dc4630; display:block; font-family:"Roboto", "Noto Sans KR", HelveticaNeue, DroidSans, Sans-serif, Helvetica; font-size:34px; font-weight:700; line-height:1; text-align:left; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide p.name  { color:#333; font-size:20px; font-weight:400; line-height:1; margin-top:3px; overflow:hidden; text-align:left; text-overflow:ellipsis; white-space:nowrap; width:200px; }

	.recom_prod_list div.swiper-wrapper div.swiper-slide a .period_warn { bottom:5px; color:#fff; font-size:22px; font-weight:500; height:auto; left:0; line-height:26px; padding:4px 0; position:absolute; text-align:center; width:100%; z-index:5; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide a .period_warn img { margin-right:10px; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide a .period_warn .period_warn_bg { background:#333; height:100%; left:0; opacity:.75; position:absolute; top:0; width:100%; z-index:-1; }

	.recom_prod_list div.swiper-wrapper div.swiper-slide .stat_btns { height:34px; margin-top:14px; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide .stat_btns:after { clear:both; content:""; display:block; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide .stat_btns span { background:#fff; border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:20px; font-weight:400; height:34px; margin-left:3px; letter-spacing:-1px; position:relative; text-align:center; width:102px; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide .stat_btns span:first-child { margin-left:0; }
	.recom_prod_list div.swiper-wrapper div.swiper-slide .stat_btns span i { color:#666; display:inline-block; font-size:20px; font-style:normal; font-weight:400; left:0; letter-spacing:-1px; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	/* edge end */
	.lp_prod_list ul li { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); height:180px; margin-top:10px; overflow:hidden; position:relative; }
	.lp_prod_list ul li:first-child { margin-top:0; }
	.lp_prod_list ul li a { display:block; height:100%; padding-left:199px; position:relative; width:100%; }
	.lp_prod_list ul li figure { height:140px; left:29px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:140px; }
	.lp_prod_list ul li figure img { position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }
	.lp_prod_list ul li div.txt_wrap { padding-top:17px; }
	.lp_prod_list ul li div.txt_wrap strong.prod_name { color:#333; display:block; font-size:34px; font-weight:500; line-height:1; margin-top:8px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:450px; }
	.lp_prod_list ul li div.txt_wrap span.prod_comp { color:#666; display:block; font-size:20px; font-weight:400; line-height:1; margin-top:6px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:450px; }
	.lp_prod_list ul li div.txt_wrap span.prod_info { color:#dc4630; display:block; font-size:26px; font-weight:500; line-height:1; margin-top:16px; }
	.lp_prod_list ul li div.txt_wrap div.dc_price { margin-top:10px; }
	.lp_prod_list ul li div.txt_wrap div.dc_price:after { clear:both; content:""; display:block; }
	.lp_prod_list ul li div.txt_wrap div.dc_price > * { float:left; }
	.lp_prod_list ul li div.txt_wrap div.dc_price p { color:#333; float:left; font-size:22px; font-weight:400; }
	.lp_prod_list ul li div.txt_wrap div.dc_price p.origin { text-decoration:line-through; }
	.lp_prod_list ul li div.txt_wrap div.dc_price img { margin:4px 15px 0; }

	.lp_cate_icon { height:34px; margin-top:22px; }
	.lp_cate_icon:after { clear:both; content:""; display:block; }
	.lp_cate_icon p { background:#fff; border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:18px; font-weight:400; height:34px; margin-left:3px; overflow:hidden; position:relative; white-space:nowrap; width:102px; }
	.lp_cate_icon p:first-child { margin-left:0; }
	.lp_cate_icon p.yellow { border:1px solid #f0af24; }
	.lp_cate_icon p span { color:#666; display:inline-block; font-size:18px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }

	.lp_prod_list ul li div.txt_wrap.dc strong.prod_name { font-size:26px; }
	.lp_prod_list ul li div.txt_wrap.dc span.prod_info { margin-top:12px; }

	.age_limit_cover { border-radius:30px; height:100%; left:0; overflow:hidden; position:absolute; top:0; width:100%; z-index:5; }
	.age_limit_dimm { background:#2f2f2f; height:100%; left:0; opacity:.8; position:absolute; top:0; width:100%; }
	.age_limit_cover p { background:url("../img/main/age_limit.png") center top no-repeat; background-size:88px auto; color:#fff; font-size:28px; font-weight:400; left:50%; padding-top:100px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); white-space:nowrap; }

	.rank_prod_list ul li { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); height:180px; margin-top:10px; overflow:hidden; padding-left:250px; position:relative; }
	.rank_prod_list ul li:first-child { margin-top:0; }

	.rank_prod_list ul li .rank_prod_stat { height:40px; left:30px; padding-left:54px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.rank_prod_list ul li .rank_prod_stat span { color:#333; font-size:14px; font-weight:400; line-height:40px; }
	.rank_prod_list ul li .rank_prod_stat span.new { color:#f0af24; }
	.rank_prod_list ul li .rank_prod_stat span.rank_u { color:#dc4630; font-size:16px; }
	.rank_prod_list ul li .rank_prod_stat span.rank_u img { margin:15px 5px 0 0; }
	.rank_prod_list ul li .rank_prod_stat span.rank_d { color:#666; font-size:16px; }
	.rank_prod_list ul li .rank_prod_stat span.rank_d img { margin:17px 5px 0 0; }
	.rank_prod_list ul li .rank_prod_stat span.rank_same img { margin:15px 0 0 10px; }

	.rank_prod_list ul li .rank_prod_stat p { color:#666; font-size:26px; font-weight:500; height:40px; left:0; line-height:40px; position:absolute; text-align:center; top:0; width:40px; }
	.rank_prod_list ul li .rank_prod_stat p.recom { background:url("../img/main/icon_recom.png") left center no-repeat; overflow:hidden; text-indent:-20000px; }
	.rank_prod_list ul li .rank_prod_stat p.badge01 { background:url("../img/main/win_badge01.png") left center no-repeat; overflow:hidden; text-indent:-20000px; }
	.rank_prod_list ul li .rank_prod_stat p.badge02 { background:url("../img/main/win_badge02.png") left center no-repeat; overflow:hidden; text-indent:-20000px; }
	.rank_prod_list ul li .rank_prod_stat p.badge03 { background:url("../img/main/win_badge03.png") left center no-repeat; overflow:hidden; text-indent:-20000px; }

	.rank_prod_list ul li figure { height:90px; left:132px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:90px; }
	.rank_prod_list ul li div.txt_wrap { padding-top:44px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:280px; }
	.rank_prod_list ul li div.txt_wrap strong.prod_name { color:#333; display:block; font-size:28px; font-weight:400; line-height:1; margin-top:13px; }
	.rank_prod_list ul li div.txt_wrap span.prod_comp { color:#666; display:block; font-size:26px; font-weight:400; line-height:1; margin-top:10px; }
	.rank_prod_list ul li a.link_btn { background:#f0af24; border-radius:35px; color:#fff; display:block; font-size:20px; font-weight:400; line-height:22px; padding:8px 0; position:absolute; right:30px; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:107px; }

	/*.main_hot_keyword { background:#666; border-bottom:2px solid #d7d7d7; margin:20px 0 0; padding:25px 80px 5px; }
	.main_hot_keyword strong { color:#fff; display:block; font-size:28px; font-weight:500; line-height:1; text-align:center; }
	.main_hot_keyword ul { font-size:0; margin-top:25px; text-align:center; }
	.main_hot_keyword ul li { display:inline-block; height:44px; margin:0 7px 14px 7px; vertical-align:top; }
	.main_hot_keyword ul li a { border:1px solid #fff; border-radius:22px; color:#fff; display:block; font-size:22px; font-weight:400; height:100%; line-height:42px; padding:0 20px; text-align:center; width:100%; }*/


	.main_hot_keyword { background:#666; border-bottom:2px solid #d7d7d7; bottom:0; margin:20px 0 0; opacity:0; padding:25px 80px 5px; position:fixed; width:100%; z-index:7; }
	.main_hot_keyword strong { color:#fff; display:block; font-size:28px; font-weight:500; line-height:1; text-align:center; }
	.main_hot_keyword ul { font-size:0; margin-top:25px; text-align:center; }
	.main_hot_keyword ul li { display:inline-block; height:44px; margin:0 7px 14px 7px; vertical-align:top; }
	.main_hot_keyword ul li a { border:1px solid #fff; border-radius:22px; color:#fff; display:block; font-size:22px; font-weight:400; height:100%; line-height:38px; padding:0 20px; text-align:center; width:100%; }



	.prod_search_wrap { background:#fff; border-bottom:1px solid #c6c6c6; height:111px; position:relative; }
	.btn_prod_cate { background:url("../img/main/btn_ham.png") no-repeat; display:block; height:19px; left:30px; margin-top:-9.5px; overflow:hidden; position:absolute; text-indent:-20000px; top:50%; width:38px; }
	.prod_search_input { background:url("../img/main/icon_search.png") 30px center no-repeat #f0f0f0; border-radius:35px; height:70px; left:98px; margin-top:-35px; position:absolute; top:50%; width:580px; }
	.prod_search_input input { background:transparent; border:none; color:#333; font-size:26px; font-weight:400; height:100%; outline:none; padding:0 30px 0 80px; width:100%; }
	.prod_search_input input:focus { border:2px solid #f0af24; border-radius:35px; }

	.prod_search_wrap a.about_ranking.prod { background:url("../img/main/icon_ques.png") no-repeat; display:block; height:40px; margin-top:-20px; overflow:hidden; position:absolute; right:20px; text-indent:-20000px; top:50%; width:40px; }

	.serv_prod_list > ul > li { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); height:180px; margin-top:10px; overflow:hidden; position:relative; }
	.serv_prod_list > ul > li:first-child { margin-top:0; }
	.serv_prod_list > ul > li a { display:block; height:100%; width:100%; }
	
	.serv_prod_list > ul > li .serv_prod_info { height:115px; padding:25px 0 0 149px; position:relative; }
	.serv_prod_list > ul > li .serv_prod_info figure { left:29px; position:absolute; top:25px; width:90px; }
	.serv_prod_list > ul > li .serv_prod_info .txt { padding-top:12px; }
	.serv_prod_list > ul > li .serv_prod_info .txt strong { color:#dc4630; display:block; font-family:"Roboto"; font-size:32px; font-weight:700; line-height:1; }
	.serv_prod_list > ul > li .serv_prod_info .txt p { color:#333; font-size:26px; font-weight:400; line-height:1.4; margin-top:3px; overflow:hidden; padding-right:10px; text-overflow:ellipsis; white-space:nowrap; }

	.serv_prod_tags { height:35px; margin-top:5px; position:relative; }
	.serv_prod_tags > ul { float:left; }
	.serv_prod_tags > ul:first-child { padding-left:149px; }
	.serv_prod_tags > ul:after { clear:both; content:""; display:block; }
	.serv_prod_tags > ul > li { border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:18px; font-weight:400; height:34px; margin-right:3px; overflow:hidden; position:relative; text-align:center; white-space:nowrap; width:102px; }
	.serv_prod_tags > ul > li > span { color:#666; display:block; font-size:18px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }

	.serv_prod_list .btn_prod_more { height:84px; margin-top:19px; }
	.serv_prod_list .btn_prod_more a { background:#fff; border:1px solid #666; border-radius:42px; color:#333; display:block; font-size:28px; font-weight:400; height:100%; line-height:78px; text-align:center; width:100%; }
	.serv_prod_list .btn_prod_more a img { margin:35px 0 0 24px; }

	.serv_prod_tags .period_warn { color:#fff; font-size:22px; font-weight:500; height:40px; left:0; line-height:40px; position:absolute; text-align:center; top:-2px; width:100%; z-index:5; }
	.serv_prod_tags .period_warn img { margin:7px 13px 0 0; }
	.serv_prod_tags .period_warn .period_warn_bg { background:#333; height:100%; left:0; opacity:.75; position:absolute; top:0; width:100%; z-index:-1; }

	.accum_done_cover { height:100%; left:0; position:absolute; top:0; width:100%; z-index:5; }
	.accum_done_dimm { background:#fff; height:100%; left:0; opacity:.8; position:absolute; top:0; width:100%; z-index:-1; }
	.accum_done_cover img { left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }

	.prod_cate_list { border-top:1px solid #c6c6c6; }
	.prod_cate_list_ttl { background:#fff; border-bottom:1px solid #c6c6c6; height:91px; position:relative; }
	.prod_cate_list_ttl strong { color:#333; font-size:28px; font-weight:700; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.prod_cate_list > ul > li { border-bottom:1px solid #f0f0f0; height:100px; }
	.prod_cate_list > ul > li:last-child { border-bottom:1px solid #c6c6c6; }
	.prod_cate_list > ul > li { background:url("../img/main/link_arrow_r.png") right 35px center no-repeat #fff; color:#333; display:block; font-size:28px; font-weight:400; height:100%; line-height:99px; overflow:hidden; padding-left:110px; position:relative; text-overflow:ellipsis; white-space:nowrap; width:100%; }
	.prod_cate_list > ul > li > a { color:#333; display:block; font-size:28px; font-weight:400; }
	.prod_cate_list > ul > li > img { height:40px; left:30px; margin-top:-20px; position:absolute; top:50%; width:40px; }
	.prod_cate_list > ul > li > i { background:#f0af24; border-radius:50%; display:block; height:11px; left:75px; overflow:hidden; position:absolute; text-indent:-20000px; top:20px; width:11px; }

	.prod_cate_list.fold > ul > li { background:none; height:auto; min-height:100px; }
	.prod_cate_list.fold > ul > li > a { background:url("../img/main/btn_fold_d.png") right 35px center no-repeat; color:#333; display:block; font-size:28px; font-weight:400; height:100%; overflow:hidden; padding-right:80px; text-overflow:ellipsis; white-space:nowrap; width:100%; }
	.prod_cate_list.fold > ul > li > a.on { background:url("../img/main/btn_fold_u.png") right 35px center no-repeat; }
	.prod_cate_list.fold > ul > li > a img { height:40px; left:30px; position:absolute; top:30px; width:40px; }
	.prod_cate_list.fold > ul > li > ul { display:none; }
	.prod_cate_list.fold > ul > li > ul > li { border-bottom:1px solid #f0f0f0; color:#333; font-size:28px; font-weight:400; height:100px; padding-left:65px; position:relative; width:100%; }
	.prod_cate_list.fold > ul > li > ul > li > a { color:#333; display:block; font-size:28px; font-weight:400; height:100%; line-height:99px; width:100%; }

	.prod_detail_slide { background:#fff; border-bottom:1px solid #c6c6c6; padding:30px 0; position:relative; }
	.prod_detail_slide_inner { height:444px; overflow:hidden; padding:0 30px; position:relative; }

	.prod_detail_slide_inner > .swiper-container { height:100%; }
	.prod_detail_slide_inner .swiper-slide { text-align:center; }

	.prod_detail_slide_inner .swiper-pagination-fraction,
	.prod_detail_slide_inner .swiper-pagination-custom,
	.prod_detail_slide_inner.swiper-container-horizontal > .swiper-pagination-bullets { height:10px; bottom:15px; }
	.prod_detail_slide_inner .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin:0 3px; }
	.prod_detail_slide_inner .swiper-pagination .swiper-pagination-bullet { background:#333; height:10px; opacity:.5; vertical-align:top; width:10px; }
	.prod_detail_slide_inner .swiper-pagination .swiper-pagination-bullet-active { opacity:.88; }

	.prod_detail_slide_inner div.video-react { width:100% !important; }

	.prod_detail_tags { height:44px; margin-top:30px; position:relative; }
	.prod_detail_tags > ul { padding:0 30px; }
	.prod_detail_tags > ul:after { clear:both; content:""; display:block; }
	.prod_detail_tags > ul > li { border:1px solid #c6c6c6; border-radius:22px; color:#666; float:left; font-size:22px; font-weight:400; height:44px; margin-right:6px; overflow:hidden; position:relative; text-align:center; white-space:nowrap; width:120px; }
	.prod_detail_tags > ul > li > span { color:#666; display:block; font-size:22px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }

	.prod_detail_tags .period_warn { color:#fff; font-size:22px; font-weight:500; height:44px; left:0; line-height:44px; position:absolute; text-align:center; top:54px; width:100%; z-index:5; }
	.prod_detail_tags .period_warn img { margin:7px 13px 0 0; }
	.prod_detail_tags .period_warn .period_warn_bg { background:#333; height:100%; left:0; opacity:.75; position:absolute; top:0; width:100%; z-index:-1; }

	.prod_detail_info { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; }
	.prod_detail_info_ttl { border-bottom:1px solid #f0f0f0; color:#dc4630; display:block; font-family:"Roboto"; font-size:70px; font-weight:700; height:126px; line-height:126px; position:relative; text-align:center; }
	.prod_detail_info_ttl a.share { background:url("../img/main/btn_share.png") no-repeat; display:block; height:36px; margin-top:-18px; overflow:hidden; position:absolute; right:30px; text-indent:-20000px; top:50%; width:37px; }

	.prod_detail_info_cont { padding:30px; }
	.prod_detail_info_cont .prod_name { color:#333; display:block; font-size:40px; font-weight:700; line-height:1; }
	.prod_detail_info_cont .prod_brand { color:#666; font-size:34px; font-weight:400; line-height:1; margin-top:20px; }
	.prod_detail_info_cont ul li { color:#333; font-size:26px; font-weight:400; line-height:1.6; margin-top:20px; padding-left:14px; position:relative; }
	.prod_detail_info_cont ul li img { margin:5px 5px 0; }
	.prod_detail_info_cont ul li:before { content:"-"; left:0; position:absolute; top:0; }

	.prod_detail_more { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; overflow:hidden; }
	.prod_detail_more_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:700; height:91px; line-height:90px; padding:0 30px; position:relative; text-align:left; }

	.prod_detail_more_cont { padding:40px 30px; }

	.prod_detail_more_cont dl { margin-top:36px; padding-left:120px; position:relative; }
	.prod_detail_more_cont dl:first-child { margin-top:0; }
	.prod_detail_more_cont dl dt { border:1px solid #c6c6c6; border-radius:17px; color:#666; font-size:18px; font-weight:400; height:34px; left:0; position:absolute; text-align:center; top:3px; width:102px; }
	.prod_detail_more_cont dl dt span { color:#666; font-size:18px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	.prod_detail_more_cont dl dd { color:#333; font-size:26px; font-weight:400; line-height:34px; }

	.reward_store_slide .swiper-slide { width:120px; }
	.reward_store_slide ul { margin:-30px 0 0 -40px; }
	.reward_store_slide ul:after { clear:both; content:""; display:block; }
	.reward_store_slide ul li { border-radius:50%; float:left; height:120px; margin:30px 0 0 40px; width:120px; }
	.reward_store_slide ul li img { border-radius:50%; height:100%; width:100%; }

	.prod_cate_search_result { margin-top:20px; padding-bottom:129px; }
	.prod_cate_search_result.prod_cate_only { margin-top:0; padding-top:96px; }

	.prod_cate_search_top { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; height:92px; margin-top:20px; position:relative; /*width:690px;*/ }
	.prod_cate_search_top p.num { color:#666; font-size:26px; font-weight:400; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.prod_cate_search_top p.num span { color:#f0af24; font-weight:500; }
	.prod_cate_search_top .btns { font-size:0; margin-top:-22px; position:absolute; right:113px; text-align:right; top:50%; }
	.prod_cate_search_top .btns.cate_page { right:30px; }
	.prod_cate_search_top .btns a { background:#fff; border:1px solid #c6c6c6; color:#666; display:inline-block; font-size:20px; font-weight:400; height:44px; line-height:40px; margin-left:10px; text-align:center; width:120px; }
	.prod_cate_search_top .btns a.on { background:#f0af24; border:1px solid #f0af24; color:#fff; }

	/* 200325 수정 */
	.online_mall_list { font-size:0; }
	.mall_list { display:inline-block; margin:0 0 20px 10px; }
	/* 200325 수정 end */

	.online_mall_top { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); height:250px; overflow:hidden; position:relative; width:690px; }
	.online_mall_top a { display:block; height:100%; padding:35px 0 0 354px; width:100%; }
	.online_mall_top a figure { left:21px; overflow:hidden; position:absolute; top:27px; width:303px; }
	.online_mall_top a figure img { width:100%; }

	.online_mall_top_txt strong { color:#333; display:block; font-size:40px; font-weight:700; line-height:1; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
	.online_mall_top_txt p.accum_info { color:#dc4630;font-size:26px; font-weight:500; line-height:1; margin-top:14px; }

	.online_mall_top_txt .cate_tags { margin-top:24px; }
	.online_mall_top_txt .detail_tags { margin-top:6px; }
	.online_mall_top_txt .cate_tags:after,
	.online_mall_top_txt .detail_tags:after { clear:both; content:""; display:block; }
	.online_mall_top_txt .cate_tags span,
	.online_mall_top_txt .detail_tags span { background:#fff; border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:18px; height:34px; margin-right:4px; overflow:hidden; position:relative; text-align:center; white-space:nowrap; width:102px; }
	.online_mall_top_txt .cate_tags span i,
	.online_mall_top_txt .detail_tags span i { color:#666; display:block; font-style:normal; font-size:18px; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	.online_mall_top_txt .cate_tags span.on,
	.online_mall_top_txt .detail_tags span.on { border:1px solid #f2ba41; }

	.online_mall_list > ul { margin-left:-10px; }
	.online_mall_list > ul:after { clear:both; content:""; display:block; }
	.online_mall_list > ul > li { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); float:left; height:472px; margin:20px 0 0 10px; overflow:hidden; position:relative; width:338px; }
	.online_mall_list > ul > li a { display:block; height:100%; padding:249px 30px 0; position:relative; width:100%; }
	.online_mall_list > ul > li a figure { border:1px solid #f0f0f0; height:220px; left:50%; margin-left:-110px; position:absolute; top:22px; width:220px; }
	.online_mall_list > ul > li a .prod_name { height:80px; position:relative; }
	.online_mall_list > ul > li a .prod_name strong { color:#333; font-size:25px; font-weight:500; left:0; line-height:30px; position:absolute; text-align:left; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.online_mall_list > ul > li a .store_name { color:#666; font-size:20px; font-weight:400; line-height:1; }

	.online_mall_list > ul > li a .prod_price { color:#333; font-size:20px; font-weight:400; line-height:1; margin-top:23px; }
	.online_mall_list > ul > li a .prod_price img.dc_arrow_s { margin:3px 11px 0; }
	.online_mall_list > ul > li a .prod_price img.icon_won { margin:3px 5px 0 0; }

	.online_mall_list > ul > li a .dc_result { color:#dc4630; font-size:26px; font-weight:500; line-height:1; margin-top:20px; }

	.online_via_wrap { left:50%; position:relative; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }
	.online_via_wrap p { color:#333; font-size:28px; font-weight:400; line-height:42px; margin-top:140px; padding:0 30px; }

	.online_detail_slide { background:#fff; padding:30px; }

	.online_detail_cont { padding:20px 30px 210px; }

	.online_mall_guide { background:#666; bottom:0; left:0; padding:30px; position:fixed; width:100%; z-index:5; }
	.online_mall_guide ul li { color:#fff; display:inline-block; font-size:20px; font-weight:300; letter-spacing:-.5px; line-height:35px; padding-left:15px; position:relative; }
	.online_mall_guide ul li:before { content:"*"; left:0; position:absolute; top:0; }

	.online_mall_guide span { color:#fff; display:inline-block; font-size:20px; font-weight:300; letter-spacing:-.5px; line-height:35px; padding-left:15px; position:relative; }
	.online_mall_guide span:before { content:"*"; left:0; position:absolute; top:0; }

	.online_detail_slide_inner { height:443px; overflow:hidden; position:relative; }
	.online_detail_slide_inner .swiper-slide { text-align:center; }
	.online_detail_slide_inner .swiper-slide .video-react { width:100% !important; }
	.online_detail_slide_inner .swiper-pagination-fraction,
	.online_detail_slide_inner .swiper-pagination-custom,
	.online_detail_slide_inner.swiper-container-horizontal > .swiper-pagination-bullets { height:10px; bottom:15px; }
	.online_detail_slide_inner .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin:0 3px; }
	.online_detail_slide_inner .swiper-pagination .swiper-pagination-bullet { background:#333; height:10px; opacity:.5; vertical-align:top; width:10px; }
	.online_detail_slide_inner .swiper-pagination .swiper-pagination-bullet-active { opacity:.88; }

	.online_detail_slide_inner video { height:443px; }

	.online_detail_tags { height:44px; margin-top:30px; position:relative; }
	.online_detail_tags:after { clear:both; content:""; display:block; }
	.online_detail_tags p.tag { background:#fff; border:1px solid #c6c6c6; border-radius:22px; color:#666; float:left; font-size:22px; font-weight:400; height:44px; margin-right:6px; position:relative; text-align:center; width:120px; }
	.online_detail_tags p.tag span { color:#666; display:block; font-size:22px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	.online_detail_tags a.share { background:url("../img/main/btn_share.png") no-repeat; display:block; height:36px; margin-top:-18px; overflow:hidden; position:absolute; right:0; text-indent:-20000px; top:50%; width:37px; }

	.online_detail_cont .no_list { color:#666; font-size:26px; font-weight:400; padding:200px 0 0; text-align:center; }

	.total_search_top { background:#fff; border-bottom:1px solid #c6c6c6; height:111px; padding:20px 30px 0; position:relative; }
	.total_search_top:after { clear:both; content:""; display:block; }
	.total_search_input { background:url("../img/main/icon_search.png") 30px center no-repeat #f0f0f0; border-radius:35px; float:left; height:70px; position:relative; width:635px; }
	.total_search_input input { background:transparent; border:none; border-radius:35px; color:#666; font-size:26px; font-weight:400; height:100%; outline:none; padding:0 80px; width:100%; }
	.total_search_input input:focus { border:2px solid #f0af24; border-radius:35px; }
	.total_search_input a.btn_input_del { background:url("../img/common/btn_input_del.png") no-repeat; display:block; height:34px; margin-top:-17px; overflow:hidden; position:absolute; right:20px; text-indent:-20000px; top:50%; width:34px; }

	.total_search_top a.btn_cancel { color:#333; font-size:26px; font-weight:400; position:absolute; right:20px; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	/* 200320 수정 */
	.online_mall_list_each { background:#fff; border:1px solid #c6c6c6; border-radius:30px; box-shadow:0 5px 5px rgba(136, 135, 132, 0.19); float:left; height:472px; overflow:hidden; position:relative; width:338px; }

	.online_mall_list_each a { display:block; height:100%; padding:249px 30px 0; position:relative; width:100%; }
	.online_mall_list_each a figure { border:1px solid #f0f0f0; height:220px; left:50%; margin-left:-110px; position:absolute; top:22px; width:220px; }
	.online_mall_list_each a .prod_name { height:80px; position:relative; }
	.online_mall_list_each a .prod_name strong { -webkit-box-orient:vertical; color:#333; display:-webkit-box; font-size:25px; font-weight:500; left:0; -webkit-line-clamp:2; line-height:30px; overflow:hidden; position:absolute; text-align:left; text-overflow:ellipsis; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }

	.online_mall_list_each a .store_name { color:#666; font-size:20px; font-weight:400; line-height:1; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }

	.online_mall_list_each a .prod_price { color:#333; font-size:20px; font-weight:400; line-height:1; margin-top:23px; }
	.online_mall_list_each a .prod_price img.dc_arrow_s { margin:3px 11px 0; }
	.online_mall_list_each a .prod_price img.icon_won { margin:3px 5px 0 0; }

	.online_mall_list_each a .dc_result { color:#dc4630; font-size:26px; font-weight:500; line-height:1; margin-top:20px; }
	/* 200320 수정 end */

	/* 200325 추가 */
	.prod_cate_pop_wrap,
	.prod_result_pop_wrap { height:100%; left:0; position:absolute; top:0; width:100%; z-index:102; }
	.prod_cate_pop_bg,
	.prod_result_pop_bg { background:#fff; height:100%; left:0; position:fixed; top:0; width:100%; }

	.prod_cate_pop_cont { padding-top:96px; }
	/* 200325 추가 end */

	/* 200502 추가 */
	.key_prod_fold { height:12px; margin-top:-6px; position:absolute; right:30px; top:50%; width:23px; }
	.key_prod_fold a { background:url("../img/mycow/faq_arrow_u.png") no-repeat; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }
	.key_prod_fold a.on { background:url("../img/mycow/faq_arrow_d.png") no-repeat; }
	/* 200502 추가 end */