@charset "UTF-8";

	.mycow_point_top { background:#fff; border-bottom:1px solid #c6c6c6; padding:30px 30px 50px; }
	.mycow_email { color:#333; display:block; font-family:"Roboto"; font-size:32px; font-weight:500; line-height:1; }

	.mycow_point_ybg { background:#fbc60d; border-radius:10px; height:216px; margin-top:30px; position:relative; }
	.mycow_point_ybg.full { border-radius:0; margin-top:0; }
	.mycow_point_txt { left:40px; position:absolute; top:45px; }
	.mycow_point_txt a { background:url("../img/mycow/mypoint_arrow.png") right 0 center no-repeat; color:#333; display:inline-block; font-size:26px; font-weight:500; line-height:1; padding-right:23px; }
	.mycow_point_txt p { color:#333; font-size:26px; font-weight:500; line-height:1; }
	.mycow_point_txt p span { font-size:22px; }
	.mycow_point_txt strong { color:#333; display:block; font-family:"Roboto"; font-size:70px; font-weight:700; line-height:1; margin-top:15px; }

	.expt_point_wrap { position:absolute; right:40px; top:45px; width:205px; }
	.expt_point_wrap dl { line-height:1; padding-left:10px; width:100%; }
	.expt_point_wrap dl:after { clear:both; content:""; display:block; }
	.expt_point_wrap dl:first-child { border-bottom:1px solid #f0f0f0; padding-bottom:26px; }
	.expt_point_wrap dl:nth-child(2) { padding-top:26px; }
	.expt_point_wrap dl dt,
	.expt_point_wrap dl dd { color:#666; float:left; line-height:1; }
	.expt_point_wrap dl dt { font-size:24px; width:100px; }
	.expt_point_wrap dl dd { font-family:"Roboto"; font-size:28px; font-weight:700; text-align:right; width:95px; }
	.expt_point_wrap p { color:#666; font-size:26px; line-height:1; margin-top:5px; padding-left:10px; }

	.mycow_point_graph { background:url("../img/mycow/mycow_point_rail.png") center top no-repeat; height:110px; margin:40px 20px 50px; position:relative; }

	.point_chk_past { background:url("../img/mycow/point_chk.png") center no-repeat; height:30px; position:absolute; top:20px; width:30px; z-index:2; }
	.point_chk_past.past01 { left:20px; }
	.point_chk_past.past02 { left:165px; }
	.point_chk_past.past03 { left:310px; }
	.point_chk_past.past04 { left:455px; }
	.point_chk_past.past05 { left:600px; }

	.point_chk_past p { color:#666; font-family:"Roboto"; font-size:26px; font-weight:400; left:50%; position:absolute; text-align:center; top:64px; -webkit-transform:translateX(-50%); transform:translateX(-50%); }

	.point_chk_now { background:url("../img/mycow/point_chk_cow.png") center no-repeat; height:70px; position:absolute; top:0; width:70px; z-index:3; }
	.point_chk_now.now01 { left:0; }
	.point_chk_now.now02 { left:145px; }
	.point_chk_now.now03 { left:290px; }
	.point_chk_now.now04 { left:435px; }
	.point_chk_now.now05 { left:580px; }

	.mycow_point_rail { height:16px; left:20px; position:absolute; top:27px; width:610px; }
	.mycow_point_bar { background:linear-gradient(90deg, rgba(250,209,99,1) 0%, rgba(242,181,50,1) 100%); border-radius:8px; height:16px; left:0; position:absolute; top:0; z-index:1; }

	.point_measure { bottom:0; height:26px; left:0; position:absolute; width:100%; }
	.point_measure p { color:#666; font-family:"Roboto"; font-size:26px; font-weight:400; position:absolute; text-align:center; }
	.point_measure p.m01 { left:29px; }
	.point_measure p.m02 { left:143px; }
	.point_measure p.m03 { left:287px; }
	.point_measure p.m04 { left:433px; }
	.point_measure p.m05 { left:578px; }

	.btn_use_point { border:1px solid #666; border-radius:42px; color:#333; display:block; font-size:28px; height:84px; position:relative; text-align:center; }
	.btn_use_point img { margin:30px 0 0 24px; }
	.btn_use_point i { color:#333; display:inline-block; font-size:28px; font-style:normal; left:50%; line-height:1; padding-right:24px; position:absolute; top:50%; -webkit-transform:translate(-50%, -60%); transform:translate(-50%, -60%); }
	.btn_use_point i:after { background:url("../img/mycow/btn_use_point.png") no-repeat; content:""; display:block; height:24px; margin-top:-12px; position:absolute; right:0; top:50%; width:12px; }

	.my_mycow_account { border-bottom:1px solid #c6c6c6; height:200px; position:relative; }
	.my_mycow_account.gbg { background:#f0f0f0; border-bottom:none; }
	.my_mycow_account p { color:#333; font-size:32px; font-weight:500; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }
	.my_mycow_account p strong { display:inline-block; font-family:"Roboto"; font-size:32px; font-weight:500; margin-top:10px; }

	.manage_acc_list { border-top:1px solid #c6c6c6; }
	.manage_acc_list dl { border-bottom:1px solid #f0f0f0; font-size:0; height:101px; }
	.manage_acc_list dl dt,
	.manage_acc_list dl dd { color:#333; display:inline-block; font-size:28px; font-weight:500; height:100%; line-height:100px; position:relative; vertical-align:top; }
	.manage_acc_list dl dt { padding-left:30px; width:267px; }
	.manage_acc_list dl dd { width:477px; }
	.manage_acc_list dl dd span { color:#666; display:inline-block; font-weight:400; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:300px; }
	.manage_acc_list dl dd a.modify_acc { border:1px solid #666; border-radius:4px; color:#333; display:block; font-size:26px; font-weight:400; height:54px; margin-top:-27px; position:absolute; right:30px; text-align:center; top:50%; width:101px; }
	.manage_acc_list dl dd a.modify_acc i { color:#333; display:block; font-size:26px; font-style:normal; font-weight:400; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }

	.modify_acc_guide { color:#666; font-size:26px; font-weight:400; letter-spacing:-.5px; line-height:42px; margin:38px 0 0 30px; padding-left:35px; position:relative; }
	.modify_acc_guide:before { background:url("../img/receipt/btn_emphasis_s.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:9px; width:28px; }

	.btn_withdraw { bottom:60px; height:80px; left:0; padding:0 30px; position:fixed; width:100%; }
	.btn_withdraw a { background:#fff; border:1px solid #666; border-radius:4px; color:#333; display:block; font-size:28px; font-weight:400; height:80px; text-align:center; position:relative; }
	.btn_withdraw a span { color:#333; display:block; font-size:28px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }

	.btn_pw_find { font-size:0; margin-top:20px; text-align:right; }
	.btn_pw_find a { background:url("../img/mycow/btn_use_point.png") right center no-repeat; color:#666; display:inline-block; font-size:26px; font-weight:400; padding-right:26px; }

	.withdraw_info_del_bg { background:#f0f0f0; height:100%; left:0; position:fixed; top:0; width:100%; z-index:101; }
	.withdraw_info_del_close { background:url("../img/common/btn_close.png") no-repeat; display:block; height:33px; left:30px; overflow:hidden; position:absolute; text-indent:-20000px; top:30px; width:33px; }

	.withdraw_info_del { background:url("../img/mycow/icon_withdraw.png") center top no-repeat; margin-top:260px; padding-top:270px; text-align:center; }
	.withdraw_info_del strong { color:#333; display:block; font-size:42px; font-weight:500; line-height:59px; text-align:center; }
	.withdraw_info_del p { color:#333; font-size:28px; font-weight:500; line-height:42px; margin-top:4px; text-align:center; }
	.withdraw_info_del p span { color:#dc4630; }

	.withdraw_chk { display:inline-block; height:40px; margin-top:60px; position:relative; }
	.withdraw_chk input[type="checkbox"] { height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.withdraw_chk label { background:url("../img/mycow/round_chk.png") no-repeat; color:#333; display:inline-block; font-size:32px; font-weight:400; line-height:40px; padding-left:53px; }
	.withdraw_chk input[type="checkbox"]:checked + label { background:url("../img/mycow/round_chk_on.png") no-repeat; }

	.withdraw_reason_wrap { border-top:1px solid #d1d1d1; padding:40px 30px 165px; }
	.withdraw_ques { color:#333; font-size:26px; font-weight:400; height:150px; letter-spacing:-1px; line-height:42px; }

	.withdraw_reason_wrap ul { margin-left:-18px; }
	.withdraw_reason_wrap ul:after { clear:both; content:""; display:block; }
	.withdraw_reason_wrap ul li { float:left; height:92px; margin:20px 0 0 18px; position:relative; width:335px; }
	.withdraw_reason_wrap ul li input[type="radio"] { -webkit-appearance:none; appearance:none; height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.withdraw_reason_wrap ul li label { border:1px solid #666; border-radius:10px; color:#333; display:inline-block; font-size:28px; font-weight:400; height:100%; position:relative; text-align:left; width:100%; }
	.withdraw_reason_wrap ul li label span { display:inline-block; font-size:28px; font-weight:400; line-height:1.2; left:29px; position:absolute; top:50%; -webkit-transform:translateY(-60%); transform:translateY(-60%); white-space:nowrap; }
	.withdraw_reason_wrap ul li input[type="radio"]:checked + label { background:url("../img/mem/input_chkd_y.png") right 19px center no-repeat; border:1px solid #f0af24; color:#f0af24; font-weight:500; }
	.withdraw_reason_wrap ul li input[type="radio"]:checked + label span { color:#f0af24; font-weight:500; }

	.withdraw_txtarea { border:1px solid #666; height:300px; margin-top:40px; position:relative; }
	.withdraw_txtarea textarea { border:none; -webkit-box-sizing:border-box; box-sizing:border-box; color:#333; font-family:"Noto Sans KR", HelveticaNeue, DroidSans, Sans-serif, Helvetica; font-size:26px; font-weight:400; height:240px; outline:none; resize:none; width:100%; }
	.withdraw_txtarea span { bottom:29px; color:#c6c6c6; font-family:"Roboto"; font-size:26px; font-weight:400; position:absolute; right:37px; }

	.mycow_point_use_wrap { margin-top:20px; padding-bottom:160px; }
	.mycow_point_use_wrap .swiper-container-autoheight { min-height:calc(100vh - 40vh); }
	.mycow_point_use_tabs { height:91px; }
	.mycow_point_use_tabs ul:after { clear:both; content:""; display:block; }
	.mycow_point_use_tabs ul li { float:left; height:91px; width:33.3%; }
	.mycow_point_use_tabs ul li a { background:#f0f0f0; border-bottom:1px solid #c6c6c6; border-right:1px solid #fff; color:#666; display:block; font-size:32px; font-weight:400; height:91px; line-height:90px; position:relative; text-align:center; }
	.mycow_point_use_tabs ul li a i { color:#666; display:inline-block; font-size:32px; font-style:normal; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	.mycow_point_use_tabs ul li:last-child a { border-right:none; }
	.mycow_point_use_tabs ul li a.on { background:#fff; border-bottom:1px solid #fff; border-right:1px solid #c6c6c6; color:#f0af24; font-weight:500; }
	.mycow_point_use_tabs ul li a.on:before { background:#f0af24; content:""; height:5px; left:0; position:absolute; top:0; width:100%; }
	.mycow_point_use_tabs ul li a.on i { color:#f0af24; font-weight:500; }

	.mycow_point_use_wrap .swiper-container .top-gnb { display:none; }

	.use01_tabs { height:131px; padding:30px 0; }
	.use01_tabs ul { font-size:0; height:70px; text-align:center; }
	.use01_tabs ul li { display:inline-block; height:70px; margin:0 5px; width:164px; }
	.use01_tabs ul li a { border:2px solid #c6c6c6; border-radius:5px; color:#666; display:block; font-size:28px; font-weight:400; height:70px; line-height:66px; position:relative; text-align:center; }
	.use01_tabs ul li a i { color:#666; display:block; font-size:28px; font-style:normal; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-60%); transform:translateY(-60%); width:100%; }
	.use01_tabs ul li a.on { border:2px solid #f0af24; color:#f0af24; font-weight:500; }
	.use01_tabs ul li a.on i { color:#f0af24; font-weight:500; }

	.use03_cont .accum_point_sum .expt_accum_list ul li a figure { left:0; }
	.use03_cont .accum_point_sum .expt_accum_list ul li a .expt_accum_point { right:0; }

	.expt_accum_list { overflow:hidden; width:100%; }
	.expt_accum_list ul { border-top:1px solid #f0f0f0; width:100%; }
	.expt_accum_list ul li { border-bottom:1px solid #f0f0f0; display:inline-block; height:171px; width:100%; }
	.expt_accum_list ul li a { display:inline-block; height:100%; padding-left:180px; position:relative; width:100%; }
	.expt_accum_list ul li a figure { left:30px; overflow:hidden; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.expt_accum_list p.no_list { color:#333; font-size:28px; font-weight:400; line-height:36px; padding:270px 0; text-align:center; }

	.expt_accum_txt { position:relative; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.expt_accum_txt p { color:#333; font-size:28px; font-weight:400; line-height:1.6; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:320px; }
	.expt_accum_txt p strong { font-weight:700; }
	.expt_accum_txt span { color:#666; display:block; font-family:"Roboto"; font-size:26px; font-weight:400; line-height:1.2; margin-top:8px; }

	.accum_use_txt { position:relative; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:340px; }
	.accum_use_txt p { color:#333; font-size:28px; font-weight:400; line-height:1.6; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
	.accum_use_txt p.bank_info { color:#6a6a6a; font-size:23px; }
	.accum_use_txt span { color:#666; display:block; font-family:"Roboto"; font-size:26px; font-weight:400; line-height:1.2; margin-top:8px; }

	.expt_accum_point { position:absolute; right:30px; text-align:right; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.expt_accum_point strong { color:#dc4630; display:block; font-family:"Roboto"; font-size:32px; font-weight:700; line-height:1; }
	.expt_accum_point p { color:#333; font-size:26px; font-weight:400; line-height:1; margin-top:16px; }
	.expt_accum_point p.red { color:#dc4630; }

	.btn_reward_cancel { margin-top:60px; }
	.btn_reward_cancel a { border:1px solid #666; border-radius:5px; color:#333; display:block; font-size:28px; font-weight:400; height:80px; line-height:78px; text-align:center; }

	.perch_list_cont.accum { padding:0 0 60px; }

	.perch_list_cont.accum ul li { border-bottom:1px solid #f0f0f0; height:auto; position:relative; }
	.perch_list_cont.accum ul li > * { position:relative; top:auto; -webkit-transform:translateY(0); transform:translateY(0); }
	.perch_list_cont.accum ul li div.list_cont_wrap { height:90px; margin:40px 0; position:relative; }
	.perch_list_cont.accum ul li div.list_cont_wrap > * { position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.accum_point_sum { padding:30px; }
	.accum_point_inner { border-bottom:2px solid #666; height:71px; }
	.accum_point_inner:after { clear:both; content:""; display:block; }
	.accum_point_inner p { color:#333; float:left; font-size:28px; font-weight:500; line-height:69px; }
	.accum_point_inner strong { color:#333; float:right; font-family:"Roboto"; font-size:32px; font-weight:700; line-height:69px; }

	.buddy_invi_cont { border-top:1px solid #c6c6c6; }
	.buddy_invi_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:500; height:91px; line-height:90px; padding:0 30px; }

	.buddy_invi_sns { font-size:0; margin-top:50px; text-align:center; }
	.buddy_invi_sns li { display:inline-block; height:100px; margin:0 15px; width:100px; }
	.buddy_invi_sns li a { display:block; height:100%; width:100%; }

	.recom_input_btn { margin-top:50px; padding:0 30px; }
	.recom_input_btn a { border:1px solid #666; border-radius:42px; color:#333; display:block; font-size:28px; font-weight:400; height:84px; line-height:82px; text-align:center; width:100%; }
	.recom_input_btn a.done { background:#e5e5e5; }
	.recom_input_btn a img { margin:29px 0 0 24px; }

	.recom_input_limit { margin-top:195px; padding:0 30px 0 64px; position:relative; }
	.recom_input_limit:before { background:url("../img/mem/icon_time_limit.png") no-repeat; content:""; height:28px; left:30px; position:absolute; top:0; width:28px; }

	.recom_input_count { background:#f0f0f0; border-bottom:1px solid #d1d1d1; border-top:1px solid #d1d1d1; color:#333; font-size:28px; font-weight:400; margin-top:35px; padding:55px 30px; text-align:center; }
	.recom_input_count strong { color:#dc4630; font-weight:700; text-decoration:underline; }

	.buddy_invi_pop { background:#fff; border-radius:30px; left:50%; margin-left:-312.5px; overflow:hidden; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:625px; z-index:151; }
	.buddy_invi_pop_t { border-bottom:1px solid #c6c6c6; color:#666; font-size:32px; font-weight:400; line-height:42px; padding:85px 0; text-align:center; }
	.buddy_invi_pop_t strong { font-weight:500; }
	.buddy_invi_pop_t strong u { border-bottom:1px solid #666; text-decoration:none; }

	.dona_list_top { border-bottom:1px solid #c6c6c6; padding:40px 0 0; }
	.dona_sum { background:#fbc60d; border-radius:10px; height:118px; margin:0 30px; position:relative; }
	.dona_sum > * { position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.dona_sum p { background:url("../img/mycow/icon_donate.png") no-repeat; color:#666; font-size:28px; font-weight:400; height:39px; left:40px; line-height:39px; padding-left:50px; }
	.dona_sum strong { color:#333; font-family:"Roboto"; font-size:46px; font-weight:700; right:40px; }

	.dona_history { font-size:0; margin-top:20px; text-align:center; }
	.dona_history p { color:#666; display:inline-block; font-size:26px; font-weight:400; margin-left:15px; padding-right:15px; position:relative; }
	.dona_history p:first-child { margin-left:0; }
	.dona_history p:last-child { padding-right:0; }
	.dona_history p:after { background:#666; content:""; height:23px; position:absolute; right:0; top:5px; width:1px; }
	.dona_history p:last-child:after { background:none; }
	.dona_history p strong { font-family:"Roboto"; font-size:28px; font-weight:700; margin-left:10px; }

	.dona_thx_bg { background:url("../img/mycow/dona_thx_bg.jpg") no-repeat; color:#c6c6c6; font-size:32px; font-weight:500; height:144px; line-height:144px; margin-top:20px; text-align:center; }

	.dona_list_cont { border-top:1px solid #c6c6c6; }
	.dona_list_cont ul li { border-bottom:1px solid #f0f0f0; height:171px; }
	.dona_list_cont ul li a { display:block; height:100%; padding:46px 30px 0; position:relative; width:100%; }
	.dona_list_cont ul li a p { color:#333; font-size:28px; font-weight:400; line-height:1.4; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:450px; }
	.dona_list_cont ul li a span { color:#666; display:block; font-family:"Roboto"; font-size:26px; font-weight:400; line-height:1; margin-top:16px; }
	.dona_list_cont ul li a strong { color:#333; display:block; font-family:"Roboto"; font-size:32px; font-weight:700; line-height:1; position:absolute; right:30px; top:50px; }

	.no_dona_list { border-top:1px solid #c6c6c6; height:calc(100vh - 493px); position:relative; }
	.no_dona_list_cont { left:0; padding:0 75px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }
	.no_dona_list_cont p.txt { color:#333; font-size:28px; font-weight:400; line-height:36px; text-align:center; }
	.no_dona_list_cont p.link { margin-top:60px; text-align:right; }
	.no_dona_list_cont p.link a { background:url("../img/mycow/donat_arrow.png") right center no-repeat; color:#333; display:inline-block; font-size:32px; font-weight:500; height:47px; line-height:47px; padding-right:62px; }

	.no_dona_list_cont.link { left:auto; padding:75px 30px; position:static; top:auto; transform:translateY(0); }
	.no_dona_list_cont.link p.link { margin-top:0; }

	.mycow_alarm_wrap > ul > li { border-bottom:1px solid #f0f0f0; height:172px; padding:30px 30px 0; position:relative; }
	.mycow_alarm_wrap > ul > li .txt { width:560px; }
	.mycow_alarm_wrap > ul > li .txt strong { color:#333; display:block; font-size:28px; font-weight:500; line-height:1; }
	.mycow_alarm_wrap > ul > li .txt ul { margin-top:10px; }
	.mycow_alarm_wrap > ul > li .txt ul li { color:#333; font-size:26px; font-weight:400; line-height:42px; padding-left:15px; position:relative; }
	.mycow_alarm_wrap > ul > li .txt ul li:before { content:"-"; left:0; position:absolute; top:0; }

	.alarm_chk { display:block; height:62px; margin-top:-31px; position:absolute; right:30px; top:50%; width:100px; }
	.alarm_chk input[type="checkbox"] { height:0; opacity:0; position:absolute; width:0; }
	.btn_alarm_chk { background:#fff; border:2px solid #c6c6c6; border-radius:31px; bottom:0; left:0; position:absolute; right:0; top:0; -webkit-transition:.4s; transition:.4s; }
	.btn_alarm_chk:before { background:#fff; border:2px solid #c6c6c6; border-radius:50%; -webkit-box-sizing:border-box; box-sizing:border-box; content:""; height:60px; left:-2px; position:absolute; top:-1px; -webkit-transition:.4s; transition:.4s; width:60px; }
	.alarm_chk input[type="checkbox"]:checked + .btn_alarm_chk { background:#f0af24; border:2px solid #f0af24; }
	.alarm_chk input[type="checkbox"]:checked + .btn_alarm_chk:before { border:2px solid #f0af24; -webkit-transform:translateX(40px); transform:translateX(40px); }

	.mycow_ver_wrap { background:url("../img/mycow/logo_ver_cow.png") center 140px no-repeat #f0f0f0; height:calc(100vh - 96px); position:relative; }
	.mycow_ver_info { left:0; position:absolute; text-align:center; top:435px; width:100%; }
	.mycow_ver_info p { color:#333; font-size:32px; font-weight:500; line-height:1; text-align:center; }
	.mycow_ver_info a { border:1px solid #666; border-radius:10px; color:#333; display:inline-block; font-size:28px; font-weight:400; height:80px; line-height:78px; margin:53px auto 0; text-align:center; width:380px; }
	.mycow_ver_info a.latest { background:#e5e5e5; border:1px solid #e5e5e5; }

	.dona_list_wrap { overflow:hidden; }

	.noti_list_cont ul li { border-bottom:1px solid #f0f0f0; height:171px; }
	.noti_list_cont ul li a { display:block; height:100%; padding:50px 30px 0; position:relative; width:100%; }
	.noti_list_cont ul li a p { color:#333; font-size:28px; font-weight:400; line-height:1.2; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:100%; }
	.noti_list_cont ul li a span { color:#333; display:block; font-family:"Roboto"; font-size:26px; font-weight:400; line-height:1; margin-top:16px; }

	.noti_cont_ttl { border-bottom:1px solid #f0f0f0; height:181px; padding:50px 30px 0; }
	.noti_cont_stat { height:50px; }
	.noti_cont_stat:after { clear:both; content:""; display:block; }
	.noti_cont_stat p { border:1px solid #666; border-radius:25px; color:#666; float:left; font-size:26px; font-weight:500; height:50px; line-height:46px; text-align:center; width:110px; }
	.noti_cont_stat p.ongoing { background:#f0af24; border:1px solid #f0af24; color:#fff; }

	.noti_cont_ttl strong { color:#333; display:block; font-size:32px; font-weight:500; line-height:1; margin-top:20px; overflow:hidden; padding-right:200px; position:relative; text-overflow:ellipsis; white-space:nowrap; }
	.noti_cont_ttl strong span { bottom:0; color:#333; display:inline-block; font-family:"Roboto"; font-size:28px; font-weight:400; margin-right:30px; position:absolute; right:20px; }

	.noti_cont_outer { height:calc(100vh - 277px); padding:50px 30px; }
	.noti_cont_inner { background:#f0f0f0; color:#333; font-size:28px; font-weight:400; height:100%; overflow-y:auto; -webkit-overflow-scrolling:touch; padding:30px; }
	.noti_cont_inner iframe { height:100% !important; width:1px !important; min-width:100% !important; *width:100%; }
	.noti_cont_inner img { width:100% !important; }

	.evt_list_wrap { overflow:hidden; padding:10px 0 106px; }
	.evt_list_wrap ul li { border-bottom:1px solid #f0f0f0; height:361px; }
	.evt_list_wrap ul li a { display:block; height:100%; padding:40px 30px; width:100%; }

	.evt_ttl_wrap { height:50px; position:relative; }
	.evt_ttl_wrap strong { color:#333; display:inline-block; font-size:28px; font-weight:400; height:50px; left:0; line-height:50px; padding-left:125px; overflow:hidden; position:absolute; text-overflow:ellipsis; top:0; white-space:nowrap; width:520px; }
	.evt_ttl_wrap strong.now:after { background:#f0af24; border-radius:50%; content:""; display:inline-block; height:11px; left:6px; position:relative; top:-15px; width:11px; }
	.evt_ttl_wrap strong span.stat { border:1px solid #666; border-radius:25px; color:#666; display:inline-block; font-size:26px; font-weight:500; height:50px; left:0; line-height:46px; position:absolute; text-align:center; top:0; width:110px; }
	.evt_ttl_wrap strong span.stat.on { background:#f0af24; border:1px solid #f0af24; color:#fff; }
	.evt_ttl_wrap span.date { color:#666; display:inline-block; font-family:"Roboto"; font-size:26px; font-weight:400; height:50px; line-height:50px; position:absolute; right:0; top:0; }

	.evt_list_wrap ul li a figure { background:#f0f0f0; height:209px; margin-top:20px; overflow:hidden; }
	.evt_list_wrap ul li a figure img { height:100%; width:100%; }

	.evt_list_band { background:#666; bottom:0; color:#fff; font-size:22px; font-weight:400; height:96px; left:0; line-height:96px; padding:0 30px; position:fixed; width:100%; z-index:5; }

	.policy_list_wrap ul li { border-bottom:1px solid #f0f0f0; height:171px; }
	.policy_list_wrap ul li a { background:url("../img/mycow/policy_arrow.png") right 30px center no-repeat; display:block; height:100%; position:relative; width:100%; }
	.policy_list_wrap ul li a p { color:#333; font-size:28px; font-weight:400; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); white-space:nowrap; }
	.policy_list_wrap ul li a p span { color:#666; font-size:24px; }

	.faq_search_wrap { background:#fff; border-bottom:1px solid #c6c6c6; padding:20px; }
	.faq_search_wrap:after { clear:both; content:""; display:block; }
	.faq_search_inner { background:url("../img/mycow/icon_search.png") 28px center no-repeat #f0f0f0; border-radius:35px; float:left; height:70px; position:relative; width:100%; }
	.faq_search_inner input { background:transparent; border:none; border-radius:35px; color:#333; font-size:28px; height:100%; padding:0 35px 0 68px; width:100%; }
	.faq_search_inner input:focus { border:2px solid #f0af24; border-radius:35px; }
	/*.faq_search_inner a.btn_search_del { display:none; }*/

	.faq_search_inner.searching { width:635px; }
	.faq_search_inner.searching a.btn_search_del { background:url("../img/mycow/btn_search_del.png") no-repeat; display:block; height:34px; margin-top:-17px; overflow:hidden; position:absolute; right:20px; text-indent:-20000px; top:50%; width:34px; }
	.faq_search_inner.searching input { padding:0 74px 0 68px; }

	.faq_search_wrap a.btn_search_cancel { color:#333; float:left; font-size:26px; font-weight:400; height:70px; line-height:70px; margin-left:20px; }

	.faq_noti_wrap { border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; }
	.faq_noti_ttl { border-bottom:1px solid #f0f0f0; height:101px; position:relative; z-index:1; }
	.faq_noti_ttl strong { color:#333; font-size:32px; font-weight:700; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.faq_noti_ttl a { background:url("../img/mycow/btn_use_point.png") right 30px center no-repeat; color:#333; display:block; font-size:28px; font-weight:400; left:0; line-height:100px; overflow:hidden; padding-left:187px; padding-right:70px; position:absolute; text-overflow:ellipsis; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); white-space:nowrap; width:100%; }

	.faq_cate_sel_wrap { padding:35px 30px 45px; }
	.faq_cate_sel_wrap strong { color:#333; display:block; font-size:32px; font-weight:700; line-height:1; }
	.faq_cate_sel_wrap ul { margin:10px 0 25px -17px; }
	.faq_cate_sel_wrap ul:after { clear:both; content:""; display:block; }
	.faq_cate_sel_wrap ul li { float:left; height:54px; margin:30px 0 0 30px; width:144px; }
	.faq_cate_sel_wrap ul li a { border:1px solid #666; border-radius:5px; color:#666; display:block; font-size:26px; font-weight:400; height:100%; line-height:46px; text-align:center; }
	.faq_cate_sel_wrap ul li a.on { border:2px solid #f0af24; color:#f0af24; font-weight:500; }
	.faq_cate_sel_wrap p { color:#dc4630; font-size:22px; font-weight:400; line-height:35px; margin:0 5px; padding-left:15px; position:relative; }
	.faq_cate_sel_wrap p:before { content:"*"; left:0; position:absolute; top:0; }

	.faq_reward_wrap { border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; }
	.faq_reward_wrap ul li { border-bottom:1px solid #f0f0f0; }
	.faq_reward_wrap ul li a { background:url("../img/mycow/faq_arrow_d.png") right 30px center no-repeat; color:#333; display:block; font-size:28px; font-weight:400; height:100px; padding:0 80px 0 30px; position:relative; width:100%; }
	.faq_reward_wrap ul li a.fold { background:url("../img/mycow/faq_arrow_u.png") right 30px center no-repeat; }
	.faq_reward_wrap ul li a > span { left:0; line-height:1.2; padding:0 80px 0 30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }
	.faq_reward_wrap ul li p { background:#f0f0f0; color:#333; display:none; font-size:26px; font-weight:400; line-height:42px; padding:30px; }

	.faq_btm_btn { background:#fff; border-top:1px solid #c6c6c6; padding:40px 30px; text-align:right; }
	.faq_btm_btn:after { clear:both; content:""; display:block; }
	.faq_btm_btn a { background:#666; border-radius:5px; color:#fff; display:inline-block; font-size:28px; font-weight:400; height:80px; position:relative; text-align:center; width:320px; }
	.faq_btm_btn a i { color:#fff; display:inline-block; font-size:28px; font-style:normal; font-weight:400; left:50%; line-height:1; padding-right:32px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -60%); transform:translate(-50%, -60%); white-space:nowrap; }
	.faq_btm_btn a i:after { background:url("../img/mycow/btn_use_point_w.png") no-repeat; content:""; height:24px; margin-top:-12px; position:absolute; right:0; top:50%; width:12px; }
	.faq_btm_btn a img { margin:27px 0 0 24px; }
	.faq_btm_btn a.cancel { background:#dc4630; }
	.faq_btm_btn a.cancel img { display:none; }
	.faq_btm_btn a.cancel i { padding-right:0; }
	.faq_btm_btn a.cancel i:after { background:none; height:0; width:0; }

	.faq_btm_btn p { color:#666; float:left; font-size:22px; font-weight:400; line-height:30px; margin-top:12px; padding-left:15px; position:relative; text-align:left; }
	.faq_btm_btn p:before { content:"*"; left:0; position:absolute; top:0; }

	.search_result_count { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; height:92px; color:#666; font-size:26px; font-weight:400; line-height:84px; padding:0 30px; }
	.search_result_count strong { color:#f0af24; font-weight:700; }

	.inquiry_guide_txt { background:#fff; border-bottom:1px solid #c6c6c6; padding:50px 30px; }
	.inquiry_guide_txt p { color:#666; font-size:24px; font-weight:400; line-height:42px; margin-top:-6px; padding-left:18px; position:relative; text-align:left; }
	.inquiry_guide_txt p:first-child { margin-top:0; }
	.inquiry_guide_txt p:before { content:"*"; left:0; position:absolute; top:0; }

	.inquiry_guide_txt.pers p { padding-left:29px; }
	.inquiry_guide_txt.pers p:before { content:"※"; }

	.inquiry_list_wrap { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; }
	.inquiry_list_ttl { color:#333; display:block; font-size:32px; font-weight:700; height:100px; line-height:100px; padding:0 30px; }

	.inquiry_list_wrap table { width:100%; }
	.inquiry_list_wrap table thead th,
	.inquiry_list_wrap table tbody td { text-align:center; vertical-align:middle; }
	.inquiry_list_wrap table thead th { background:#f0f0f0; border-right:1px solid #fff; color:#333; font-size:26px; height:90px; }
	.inquiry_list_wrap table thead th:last-child { border-right:none; }

	.inquiry_list_wrap table tbody td { border-bottom:1px solid #f0f0f0; color:#333; font-size:22px; font-weight:400; height:102px; padding:0 14px; }
	.inquiry_list_wrap table tbody td.no_list { border-bottom:none; color:#333; font-size:26px; font-weight:400; height:auto; padding:90px 0; }

	.inquiry_list_wrap table tbody td a { color:#333; display:block; font-weight:700; line-height:1.4; margin-top:10px; overflow:hidden; padding-left:25px; text-overflow:ellipsis; white-space:nowrap; width:310px; }

	.inquiry_list_wrap table tbody td p.stat { border-radius:3px; display:inline-block; font-size:22px; font-weight:400; line-height:1; padding:4px 0; text-align:center; width:78px; }
	.inquiry_list_wrap table tbody td p.stat.wait { border:1px solid #666; color:#666; }
	.inquiry_list_wrap table tbody td p.stat.done { border:1px solid #f0af24; color:#f0af24; }

	.my_inquiry_wrap { border-bottom:1px solid #c6c6c6; }
	.my_inquiry_ttl { border-bottom:1px solid #f0f0f0; height:183px; padding:54px 30px 0; position:relative; }
	.my_inquiry_ttl strong { color:#333; display:block; font-size:26px; font-weight:500; line-height:50px; overflow:hidden; padding-right:140px; position:relative; text-overflow:ellipsis; white-space:nowrap; }
	.my_inquiry_ttl strong span.stat { border-radius:25px; display:block; height:50px; line-height:46px; position:absolute; right:0; text-align:center; top:0; width:118px; }
	.my_inquiry_ttl strong span.stat.wait { border:1px solid #666; color:#666; }
	.my_inquiry_ttl strong span.stat.done { border:1px solid #f0af24; color:#f0af24; }
	.my_inquiry_ttl p { font-size:0; line-height:1; margin-top:5px; }
	.my_inquiry_ttl p span { color:#666; display:inline-block; font-size:22px; font-weight:400; margin-right:20px; padding-right:21px; position:relative; }
	.my_inquiry_ttl p span:after { background:#666; content:""; height:20px; margin-top:-10px; position:absolute; right:0; top:50%; width:1px; }
	.my_inquiry_ttl p span:last-child { margin-right:0; padding-right:0; }
	.my_inquiry_ttl p span:last-child:after { background:none; }
	.my_inquiry_ttl p span i { font-family:"Roboto"; font-style:normal; margin-left:12px; }

	.my_inquiry_ques { border-bottom:1px solid #f0f0f0; padding:50px 30px 40px; }
	.my_inquiry_ques p { color:#333; font-size:26px; font-weight:400; line-height:42px; padding-left:70px; position:relative; }
	.my_inquiry_ques p:before { background:url("../img/mycow/icon_q.png") no-repeat; content:""; height:50px; left:0; position:absolute; top:0; width:50px; }
	.my_inquiry_ques figure { margin:22px 0 0 -10px; padding:0 30px 0 70px; }
	.my_inquiry_ques figure:after { clear:both; content:""; display:block; }
	.my_inquiry_ques figure img { float:left; height:190px; margin:10px 0 0 10px; width:190px; }

	.my_inquiry_answ { background:#f0f0f0; padding:30px; }
	.my_inquiry_answ p { color:#333; font-size:26px; font-weight:400; line-height:42px; padding-left:70px; position:relative; }
	.my_inquiry_answ p:before { background:url("../img/mycow/icon_a.png") no-repeat; content:""; height:50px; left:0; position:absolute; top:0; width:50px; }
	.answ_date { color:#666; display:block; font-size:22px; font-weight:400; line-height:1; margin-top:20px; text-align:right; }

	.inquiry_input_field { border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; padding:46px 30px 150px; }
	.inquiry_input_field dl { font-size:0; height:80px; margin-top:20px; }
	.inquiry_input_field dl:first-child { margin-top:0; }
	.inquiry_input_field dl:after { clear:both; content:""; display:block; }
	.inquiry_input_field dl dt,
	.inquiry_input_field dl dd { display:inline-block; height:80px; line-height:80px; vertical-align:top; }
	.inquiry_input_field dl dt { color:#333; font-size:28px; font-weight:400; width:139px; }
	.inquiry_input_field dl dd { border:1px solid #c6c6c6; width:549px; }
	.inquiry_input_field dl dd input { border:none; color:#333; float:left; font-size:26px; font-weight:400; height:100%; padding:0 20px; width:100%; }
	.inquiry_input_field dl dd a.btn_sel { background:url("../img/mycow/faq_arrow_d.png") center no-repeat #f0f0f0; border-left:1px solid #c6c6c6; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:81px; }

	.inquiry_input_field div.txtarea { border:1px solid #c6c6c6; height:412px; margin:40px 0; position:relative; }
	.inquiry_input_field div.txtarea textarea { border:none; -webkit-box-sizing:border-box; box-sizing:border-box; color:#333; font-family:"Noto Sans KR", HelveticaNeue, DroidSans, Sans-serif, Helvetica; font-size:26px; font-weight:400; height:350px; outline:none; padding:30px 30px 60px; resize:none; width:100%; }
	.inquiry_input_field div.txtarea span { bottom:24px; color:#b4b3b3; display:inline-block; font-family:"Roboto"; font-size:28px; font-weight:400; line-height:1; position:absolute; right:20px; z-index:5; }

	.inquiry_img_upload { border:1px solid #c6c6c6; height:80px; position:relative; }
	.inquiry_img_upload input[type="text"] { background:transparent; border:none; color:#666; font-size:28px; font-weight:400; height:100%; padding:0 100px 0 30px; width:100%; }
	.inquiry_img_upload p.btn_upload { height:45px; overflow:hidden; position:absolute; right:30px; top:17px; width:45px; }
	.inquiry_img_upload p.btn_upload input[type="button"] { background:url("../img/mycow/btn_img_upload.png") no-repeat; border:none; height:45px; left:0; padding:0; position:absolute; top:0; width:45px; }
	.inquiry_img_upload p.btn_upload input[type="file"] { border:none; height:45px; left:0; opacity:0; padding:0; position:absolute; top:0; width:45px; z-index:5; }

	.img_limit { color:#666; font-size:22px; font-weight:400; line-height:1; margin-top:20px; }

	.inq_type_pop { background:#f0f0f0; border-radius:10px; left:50%; margin-left:-345px; padding:0 5px 5px; position:absolute; top:300px; width:690px; z-index:151; }
	.inq_type_pop_ttl { height:100px; position:relative; }
	.inq_type_pop_ttl strong { color:#666; font-size:32px; font-weight:700; left:40px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.inq_type_pop_ttl a { background:url("../img/mycow/btn_type_close.png") no-repeat; display:block; height:33px; overflow:hidden; position:absolute; right:32px; text-indent:-20000px; top:26px; width:33px; }

	.inq_type_pop ul li { border-bottom:1px solid #f0f0f0; height:91px; }
	.inq_type_pop ul li a { background:#fff; color:#666; display:block; font-size:32px; font-weight:400; height:90px; line-height:90px; text-align:center; }
	.inq_type_pop ul li:last-child a { border-bottom-left-radius:5px; border-bottom-right-radius:5px; }

	/* 광고적립 */
	.adv_accum_top { background:#fff; border-bottom:1px solid #f0f0f0; padding:72px 100px 45px; position:relative; }
	.adv_accum_top.single { height:360px; padding:0; }

	.purch_reason { bottom:80px; color:#333; font-size:32px; font-weight:500; left:0; line-height:42px; padding:0 70px; position:absolute; text-align:center; top:auto; width:100%; }
	.purch_reason strong { font-weight:700; }
	.purch_reason span { color:#666; display:block; font-size:26px; margin-top:10px; }

	.adv_accum_rate_wrap { padding:80px 30px 210px; position:relative; }

	.adv_accum_rate_list ul li { height:92px; margin-top:20px; }
	.adv_accum_rate_list ul li a { background:url("../img/mycow/list_chk_off.png") right 29px center no-repeat #fff; border:1px solid #666; border-radius:10px; color:#333; display:block; font-size:28px; font-weight:400; height:100%; line-height:90px; overflow:hidden; padding:0 86px 0 29px; text-overflow:ellipsis; white-space:nowrap; }
	.adv_accum_rate_list ul li a.on { background:url("../img/mycow/list_chk_on.png") right 29px center no-repeat #fff; border:2px solid #f0af24; color:#f0af24; font-weight:500; }

	.rate_list_more { height:62px; margin-top:50px; text-align:center; }
	.rate_list_more a { background:url("../img/mycow/btn_more_down.png") no-repeat; display:inline-block; height:62px; overflow:hidden; text-indent:-20000px; width:113px; }

	.survey_ques_ttl { position:relative; text-align:center; width:100%; }
	.next_survey_txt { color:#666; display:block; font-size:20px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:-30px; width:100%; }
	.survey_ques_ttl h3 { color:#666; display:block; font-size:32px; font-weight:700; line-height:1; margin-top:5px; text-align:center; }

	.ques_pager { height:70px; margin-top:47px; text-align:center; }
	.ques_pager p { background:#fff; border:1px solid #c6c6c6; border-radius:35px; color:#c6c6c6; display:inline-block; font-family:"Roboto"; font-size:32px; font-weight:900; height:70px; line-height:68px; text-align:center; width:140px; }

	.survey_ques_ttl strong { color:#333; display:block; font-size:32px; font-weight:700; margin-top:24px; text-align:center; }

	.survey_shoose_txt { color:#666; display:block; font-size:26px; font-weight:400; margin-top:18px; text-align:center; }

	.smile_rate_wrap ul { font-size:0; text-align:center; }
	.smile_rate_wrap ul li { display:inline-block; margin:0 40px; width:110px; }
	.smile_rate_wrap ul li a { color:#c6c6c6; display:block; font-size:26px; font-weight:500; padding-top:125px; text-align:center; }
	.smile_rate_wrap ul li.smile_rate01 a { background:url("../img/mycow/smile_rate01_off.png") center top no-repeat; }
	.smile_rate_wrap ul li.smile_rate02 a { background:url("../img/mycow/smile_rate02_off.png") center top no-repeat; }
	.smile_rate_wrap ul li.smile_rate03 a { background:url("../img/mycow/smile_rate03_off.png") center top no-repeat; }
	.smile_rate_wrap ul li a.on { color:#f0af24; font-weight:700; }
	.smile_rate_wrap ul li.smile_rate01 a.on { background:url("../img/mycow/smile_rate01_on.png") center top no-repeat; }
	.smile_rate_wrap ul li.smile_rate02 a.on { background:url("../img/mycow/smile_rate02_on.png") center top no-repeat; }
	.smile_rate_wrap ul li.smile_rate03 a.on { background:url("../img/mycow/smile_rate03_on.png") center top no-repeat; }

	.thumb_rate_wrap ul { font-size:0; text-align:center; }
	.thumb_rate_wrap ul li { display:inline-block; margin:0 45px; width:100px; }
	.thumb_rate_wrap ul li a { color:#c6c6c6; display:block; font-size:26px; font-weight:500; padding-top:153px; text-align:center; }
	.thumb_rate_wrap ul li.thumb_rate01 a { background:url("../img/mycow/thumb_rate01_off.png") center top no-repeat; }
	.thumb_rate_wrap ul li.thumb_rate02 a { background:url("../img/mycow/thumb_rate02_off.png") center top no-repeat; }
	.thumb_rate_wrap ul li a.on { color:#f0af24; font-weight:700; }
	.thumb_rate_wrap ul li.thumb_rate01 a.on { background:url("../img/mycow/thumb_rate01_on.png") center top no-repeat; }
	.thumb_rate_wrap ul li.thumb_rate02 a.on { background:url("../img/mycow/thumb_rate02_on.png") center top no-repeat; }

	/* 200512 수정 */
	.cmplx_rate_wrap ul { font-size:0; text-align:center; }
	.cmplx_rate_wrap ul li { display:inline-block; height:156px; margin:0 40px; position:relative; width:110px; }
	.cmplx_rate_wrap ul li a { color:#c6c6c6; display:block; font-size:26px; font-weight:500; height:100%; position:relative; text-align:center; }
	.cmplx_rate_wrap ul li a.on { color:#f0af24; font-weight:700; }
	.cmplx_rate_wrap ul li a span { bottom:0; display:inline-block; left:50%; line-height:1; position:absolute; -webkit-transform:translateX(-50%); transform:translateX(-50%); }

	.cmplx_rate_wrap ul li.icon_good a { background:url("../img/mycow/smile_rate01_off.png") center top no-repeat; padding-top:125px; }
	.cmplx_rate_wrap ul li.icon_normal a { background:url("../img/mycow/smile_rate02_off.png") center top no-repeat; padding-top:125px; }
	.cmplx_rate_wrap ul li.icon_bad a { background:url("../img/mycow/smile_rate03_off.png") center top no-repeat; padding-top:125px; }
	.cmplx_rate_wrap ul li.icon_up a { background:url("../img/mycow/thumb_rate01_off.png") center top no-repeat; padding-top:153px; }
	.cmplx_rate_wrap ul li.icon_down a { background:url("../img/mycow/thumb_rate02_off.png") center top no-repeat; padding-top:153px; }

	.cmplx_rate_wrap ul li.icon_good a.on { background:url("../img/mycow/smile_rate01_on.png") center top no-repeat; }
	.cmplx_rate_wrap ul li.icon_normal a.on { background:url("../img/mycow/smile_rate02_on.png") center top no-repeat; }
	.cmplx_rate_wrap ul li.icon_bad a.on { background:url("../img/mycow/smile_rate03_on.png") center top no-repeat; }
	.cmplx_rate_wrap ul li.icon_up a.on { background:url("../img/mycow/thumb_rate01_on.png") center top no-repeat; }
	.cmplx_rate_wrap ul li.icon_down a.on { background:url("../img/mycow/thumb_rate02_on.png") center top no-repeat; }
	/* 200512 수정 end */

	.star_rate_wrap { height:80px; margin:0 auto; width:545px; }
	.star_rate_wrap ol li { float:left; height:80px; margin-left:30px; width:85px; }
	.star_rate_wrap ol li:first-child { margin-left:0; }
	.star_rate_wrap ol li a { display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }

	.star_rate_wrap.star0 { background:url("../img/mycow/star_rating0.png") no-repeat; }
	.star_rate_wrap.star1 { background:url("../img/mycow/star_rating1.png") no-repeat; }
	.star_rate_wrap.star2 { background:url("../img/mycow/star_rating2.png") no-repeat; }
	.star_rate_wrap.star3 { background:url("../img/mycow/star_rating3.png") no-repeat; }
	.star_rate_wrap.star4 { background:url("../img/mycow/star_rating4.png") no-repeat; }
	.star_rate_wrap.star5 { background:url("../img/mycow/star_rating5.png") no-repeat; }

	.btn_next_ques { height:47px; margin-top:68px; text-align:right; }
	.btn_next_ques a { background:url("../img/mycow/donat_arrow_off.png") right center no-repeat; color:#333; display:inline-block; font-size:32px; font-weight:500; height:47px; line-height:47px; padding-right:62px; }
	.btn_next_ques a.on { background:url("../img/mycow/donat_arrow.png") right center no-repeat; }

	.adv_accum_txt_input { border:1px solid #c6c6c6; height:238px; position:relative; }
	.adv_accum_txt_input textarea { border:none; -webkit-box-sizing:border-box; box-sizing:border-box; color:#333; font-family:"Noto Sans KR", HelveticaNeue, DroidSans, Sans-serif, Helvetica; font-size:28px; font-weight:400; height:180px; line-height:42px; outline:none; padding:40px 30px; resize:none; width:100%; }
	.adv_accum_txt_input span { bottom:20px; color:#c6c6c6; font-family:"Roboto"; font-size:26px; font-weight:400; position:absolute; right:37px; }

	.btm_stick_point { background:#666; bottom:100px; color:#fff; font-size:22px; font-weight:500; height:40px; left:0; line-height:40px; position:fixed; text-align:center; width:100%; z-index:50; }

	.adv_accum_bnr { border-bottom:1px solid #c6c6c6; }

	.adv_accum_prod { border-top:1px solid #c6c6c6; }
	.adv_accum_prod strong { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:700; height:91px; line-height:90px; padding:0 30px; }

	.adv_accum_prod_cont { padding:35px 30px 200px; }
	.adv_accum_prod_txt { color:#333; font-size:26px; font-weight:400; line-height:42px; }
	.adv_accum_prod_link { border:1px solid #666; border-radius:42px; color:#333; display:block; font-size:28px; font-weight:400; height:84px; line-height:82px; margin-top:34px; text-align:center; }
	.adv_accum_prod_link img { margin:26px 14px 0 0; }

	.adv_charge_guide { color:#666; font-size:26px; font-weight:400; line-height:42px; margin:38px 0 0; padding-left:35px; position:relative; }
	.adv_charge_guide:before { background:url("../img/receipt/btn_emphasis_s.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:9px; width:28px; }

	.adv_accum_movie { height:424px; position:relative; }
	/* 광고적립 end */

	/* 문의하기 이미지 목록 */
	.inquiry_img_list ul li { border:1px solid #c8c8c8; color:#666; font-size:28px; font-weight:400; height:80px; line-height:78px; margin-bottom:20px; overflow:hidden; padding:0 110px 0 30px; position:relative; text-overflow:ellipsis; white-space:nowrap; }
	.inquiry_img_list ul li span { display:inline-block; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width:540px; }
	.inquiry_img_list ul li a { background:url("../img/mycow/btn_img_del.png") no-repeat; display:block; height:45px; margin-top:-22.5px; overflow:hidden; position:absolute; right:30px; text-indent:-20000px; top:50%; width:45px; }
	/* 문의하기 이미지 목록 end */

	.barcode_dimm p.mb_limit { background:url("../img/common/icon_limit.png") center top no-repeat; }

	/* 문의하기 이미지 확대 */
	.inq_img_zoom_wrap { display:none; left:50%; margin-left:-40%; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:80%; z-index:151; }
	.inq_img_zoom_wrap button { background:url("../img/mycow/btn_close_w.png") no-repeat; border:none; height:33px; outline:none; position:absolute; right:0; top:-53px; width:33px; z-index:5; }
	.inq_img_zoom_wrap img { width:100%; }
	/* 문의하기 이미지 확대 end */

	.no_search_result { border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; color:#333; font-size:26px; font-weight:400; padding:100px 30px; text-align:center; }