.rankingTransition-appear{
	opacity: 0;
}

.rankingTransition-appear.rankingTransition-appear-active{
	opacity: 1;
	transition: all .5s ease-in-out;
}

.upTransition-appear{
	opacity: 0;
	transform: translateY(100px);
}
.upTransition-appear.upTransition-appear-active{
	opacity: 1;
	transform: translateY(0px);
	transition: all .4s ease-in-out;
}

.leftTransition-appear{
	opacity: 0;
	transform: translateX(-350px);
}
.leftTransition-appear.leftTransition-appear-active{
	opacity: 1;
	transform: translateX(0px);
	transition: all .2s ease-in-out;
}

.leftTransition-leave {
	opacity: 1;
	transform: translateX(0px);
}
.leftTransition-leave.leftTransition-leave-active {
	opacity: 0;
	transform: translateX(-350px);
	transition: all .2s ease-in-out;
}

.rightTransition-appear{
	opacity: 0;
	transform: translateX(350px);
}
.rightTransition-appear.rightTransition-appear-active{
	opacity: 1;
	transform: translateX(0px);
	transition: all .2s ease-in-out;
}

.rightTransition-leave {
	opacity: 1;
	transform: translateX(0px);
}
.rightTransition-leave.rightTransition-leave-active {
	opacity: 0;
	transform: translateX(350px);
	transition: all .2s ease-in-out;
}