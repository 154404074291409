@charset "UTF-8";

	.mem_header { border-bottom:1px solid #c6c6c6; height:96px; left:0; position:fixed; top:0; width:100%; z-index:50; }
	.mem_header.cate { z-index:105; }
	.mem_header p.btn_close { height:33px; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:33px; }
	.mem_header p.btn_close a { background:url("../img/mem/btn_close.png") no-repeat; background-size:100% auto; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }
	.mem_header h2 { color:#333; display:block; font-size:34px; font-weight:700; left:50%; line-height:1.4; overflow:hidden; position:absolute; text-align:center; text-overflow:ellipsis; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); white-space:nowrap; width:480px; }

	.mem_header p.btn_prev { height:43px; left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:24px; }
	.mem_header p.btn_prev a { background:url("../img/mem/btn_prev.png") no-repeat; background-size:100% auto; display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }

	.login_cont { padding:262px 30px 0; position:relative; }
	.login_cont h3.login_logo { background:url("../img/mem/login_logo.png") no-repeat; background-size:100% auto; display:block; height:78px; left:50%; overflow:hidden; position:absolute; text-indent:-20000px; top:104px; -webkit-transform:translateX(-50%); transform:translateX(-50%); width:332px; }
	.login_cont input { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:28px; font-weight:400; height:100px; margin-top:18px; outline:none; padding:0 28px; width:100%; }
	.login_cont input:first-child { margin-top:0; }
	.login_cont input::-webkit-input-placeholder { color:#c6c6c6; }
	.login_cont input:focus { border:2px solid #f0af24; outline:none; }

	.login_mem_btns { font-size:0; margin-top:60px; text-align:center; }
	.login_mem_btns p { display:inline-block; margin-left:50px; padding-right:50px; position:relative; vertical-align:top; }
	.login_mem_btns p:first-child { margin-left:0; }
	.login_mem_btns p:last-child { padding-right:0; }
	.login_mem_btns p:after { background:#666; content:""; height:20px; position:absolute; right:0; top:2px; width:1px; }
	.login_mem_btns p:last-child:after { background:none; width:0; }
	.login_mem_btns p a { color:#666; font-size:26px; font-weight:500; }

	.btn_login { bottom:0; height:100px; left:0; position:fixed; width:100%; z-index:5; }
	.btn_login a { background:#c6c6c6; color:#fff; display:block; font-size:32px; font-weight:500; height:100%; line-height:100px; text-align:center; width:100%; }
	.btn_login.on a { background:#333; }

	.idpw_nomatch_pop { background:#fff; border-radius:30px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:85%; z-index:151; }
	.idpw_nomatch_pop p { color:#333; font-size:28px; font-weight:400; line-height:42px; padding:70px 55px; text-align:center; }
	.idpw_nomatch_pop a { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; line-height:100px; margin-top:0; text-align:center; }

	.idpw_nomatch_pop.alertPop { -webkit-transform:translate(-50%, 0); transform:translate(-50%, 0); }
	.idpw_nomatch_pop.alertPop.inquiry { -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }
	.idpw_nomatch_pop.alertPop.noResultPop { position:fixed; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); }

	.find_idpw_tabs { height:91px; }
	.find_idpw_tabs:after { clear:both; content:""; display:block; }
	.find_idpw_tabs p { float:left; height:91px; width:50%; }
	.find_idpw_tabs p a { background:#f0f0f0; border:1px solid #c6c6c6; border-top:none; color:#666; display:block; font-size:32px; font-weight:400; height:100%; line-height:90px; position:relative; text-align:center; width:100%; }
	.find_idpw_tabs p.id a { border-left:none; }
	.find_idpw_tabs p.pw a { border-right:none; }
	.find_idpw_tabs p a.on { background:#fff; border:none; color:#f0af24; font-weight:500; }
	.find_idpw_tabs p a.on:after { background:#f0af24; content:""; height:5px; left:0; position:absolute; top:0; width:100%; }

	.find_idpw_wrap { background:#fff; padding:0 30px 130px; }
	/*.find_idpw_wrap.pw { display:none; }*/

	.find_idpw_ttl { color:#333; display:inline-block; font-size:40px; font-weight:400; line-height:56px; margin-top:70px; }

	.find_idpw_wrap fieldset { margin-top:73px; }
	.find_idpw_radio { height:40px; line-height:40px; margin-top:40px; position:relative; }
	.find_idpw_radio:first-child { margin-top:0; }
	.find_idpw_radio input[type="radio"] { -webkit-appearance:none; appearance:none; height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.find_idpw_radio label { background:url("../img/common/radio_off.png") no-repeat; background-size:40px auto; color:#333; display:inline-block; font-size:28px; font-weight:500; height:40px; line-height:40px; padding-left:55px; }
	.find_idpw_radio label span { color:#666; }
	.find_idpw_radio input[type="radio"]:checked + label { background:url("../img/common/radio_on.png") no-repeat; }

	.find_id_info_input { display:none; margin-top:30px; }
	.find_id_info_input input { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:28px; font-weight:400; height:100px; margin-top:20px; padding:0 28px; width:100%; }
	.find_id_info_input input:first-child { margin-top:0; }
	.find_id_info_input input::-webkit-input-placeholder { color:#c6c6c6; }
	.find_id_info_input input:focus { border:2px solid #f0af24; outline:none; }

	.find_pw_input input { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:28px; font-weight:400; height:100px; margin-top:20px; padding:0 80px 0 28px; width:100%; }
	.find_pw_input input.ok { background:url("../img/mem/input_chkd_y.png") right 28px center no-repeat; background-size:28px auto; }
	.find_pw_input input.wrong { background:url("../img/mem/input_wrong.png") right 29px center no-repeat; background-size:23px auto; border:1px solid #dc4630; padding:0 80px 0 28px; }
	.find_pw_input input:first-child { margin-top:0; }
	.find_pw_input input::-webkit-input-placeholder { color:#c6c6c6; }
	.find_pw_input input:focus { border:2px solid #f0af24; outline:none; }

	.find_id_result { background:#f0f0f0; display:inline-block; height:250px; margin-top:60px; position:relative; width:100%; }
	.find_id_result p { color:#333; font-size:32px; font-weight:400; left:50%; line-height:42px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); white-space:nowrap; }
	.find_id_result p strong { color:#dc4630; font-weight:700; }

	.certi_ok_pop { background:url("../img/mem/icon_chkd.png") center 60px no-repeat #3b3b3b; background-size:100px auto; border-radius:30px; color:#fff; font-size:32px; font-weight:400; height:280px; left:50%; padding-top:190px; position:fixed; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:460px; z-index:151; }
	.certi_ok_pop.calc { -webkit-transform:translate(-50%, 0); transform:translate(-50%, 0); }

	.find_pw_info_input { display:none; margin-top:30px; }
	.find_pw_info_input input { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:28px; font-weight:400; height:100px; margin-top:20px; padding:0 80px 0 28px; width:100%; }
	.find_pw_info_input input.ok { background:url("../img/mem/input_chkd_y.png") right 28px center no-repeat; background-size:28px auto; }
	.find_pw_info_input input:first-child { margin-top:0; }
	.find_pw_info_input input::-webkit-input-placeholder { color:#c6c6c6; }
	.find_pw_info_input input:focus { border:2px solid #f0af24; outline:none; }

	.time_remain { color:#dc4630; font-size:26px; font-weight:400; line-height:1; margin-top:20px; }
	.certi_time_limit { background:url("../img/mem/icon_time_limit.png") left 6px no-repeat; background-size:28px auto; color:#666; font-size:26px; font-weight:400; line-height:35px; margin-top:20px; padding-left:38px; }

	.certi_num_input { border:1px solid #c6c6c6; color:#333; font-size:28px; font-weight:400; height:100px; margin-top:20px; position:relative; width:100%; }
	.certi_num_input input { -webkit-appearance:none; appearance:none; border:none; color:#333; font-size:28px; font-weight:400; height:98px; padding:0 168px 0 28px; }
	.certi_num_input input:focus { border:2px solid #f0af24; outline:none; }
	.certi_num_input a.btn_resend { border:1px solid #666; border-radius:5px; color:#333; display:block; font-size:26px; font-weight:400; height:54px; line-height:52px; position:absolute; right:19px; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:120px; }

	.pw_wran { color:#dc4630; font-size:26px; font-weight:400; line-height:1; margin-top:20px; }
	.pw_wran.withdraw { float:left; margin-top:0; }

	.memjoin_greet_wrap { background:url("../img/mem/icon_login_greet.png") center top no-repeat; margin-top:36px; padding-top:280px; }
	.memjoin_greet_wrap > * { line-height:1; text-align:center; }
	.memjoin_greet_wrap strong { color:#333; display:block; font-size:40px; font-weight:500; }
	.memjoin_greet_wrap p { color:#666; font-size:28px; font-weight:400; margin-top:20px; }

	.memjoin_policy_agree { margin-top:40px; padding-bottom:150px; }
	.policy_agree_all { border-bottom:1px solid #f0f0f0; height:105px; position:relative; }
	.policy_agree_all input[type="checkbox"] { height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.policy_agree_all label { background:url("../img/mem/chk_all_off.png") right 30px center no-repeat; color:#333; display:block; font-size:32px; font-weight:700; line-height:104px; padding:0 30px; }
	.policy_agree_all input[type="checkbox"]:checked + label { background:url("../img/mem/chk_all_on.png") right 30px center no-repeat; }

	.policy_agree_each { border-bottom:1px solid #f0f0f0; height:101px; position:relative; }
	.policy_agree_each a.policy_link { color:#333; display:block; font-size:26px; font-weight:400; left:40px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.agree_each { height:40px; margin-top:-20px; position:absolute; right:40px; top:50%; width:40px; }
	.agree_each input[type="checkbox"] { height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.agree_each label { background:url("../img/mem/chk_off.png") no-repeat; display:block; font-size:0; height:40px; overflow:hidden; text-indent:-20000px; width:40px; }
	.agree_each input[type="checkbox"]:checked + label { background:url("../img/mem/chk_on.png") no-repeat; }

	.policy_agree_warn { color:#666; font-size:25px; font-weight:400; line-height:35px; margin-top:18px; padding:0 30px 0 68px; position:relative; }
	.policy_agree_warn:before { background:url("../img/mem/icon_time_limit.png") no-repeat; content:""; height:28px; left:30px; position:absolute; top:5px; width:28px; }

	.memjoin_id_input { padding:80px 30px; }

	h3.memjoin_input_ttl { color:#333; display:block; font-size:40px; font-weight:400; line-height:56px; }
	.memjoin_input_subttl { color:#666; display:block; font-size:28px; font-weight:400; line-height:42px; margin-top:20px; }
	.memjoin_input_subttl strong { font-weight:700; }

	.memjoin_id_input fieldset { margin-top:80px; }

	.memjoin_input_email { border:1px solid #c6c6c6; height:100px; position:relative; }
	.memjoin_input_email.ok { background:url("../img/mem/input_chkd_y.png") right 28px center no-repeat; background-size:28px auto; }
	.memjoin_input_email.wrong { background:url("../img/mem/input_wrong.png") right 29px center no-repeat; background-size:23px auto; border:1px solid #dc4630; }
	.memjoin_input_email:after { clear:both; content:""; display:block; }
	.memjoin_input_email > * { float:left; }
	.memjoin_input_email input { -webkit-appearance:none; appearance:none; border:none; color:#333; font-size:28px; font-weight:400; height:96px; padding:0 30px; }
	.memjoin_input_email input:focus { outline:none; }
	.memjoin_input_email p.email_at { color:#333; font-size:28px; font-weight:400; height:98px; line-height:98px; text-align:center; width:44px; }
	.memjoin_input_email input.email_id { width:322px; }
	.memjoin_input_email input.email_domain { width:235px; }

	input.memjoin_input_pw { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:26px; font-weight:400; height:100px; margin-top:20px; padding:0 80px 0 28px; width:100%; }
	input.memjoin_input_pw:focus { border:2px solid #f0af24; outline:none; }
	input.memjoin_input_pw.ok { background:url("../img/mem/input_chkd_y.png") right 28px center no-repeat; background-size:28px auto; }
	input.memjoin_input_pw.wrong { background:url("../img/mem/input_wrong.png") right 29px center no-repeat; background-size:23px auto; border:1px solid #dc4630; }

	.email_sel_pop { background:#fff; border-radius:30px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:640px; z-index:151; }
	.email_sel_popttl { border-bottom:1px solid #c6c6c6; color:#333; font-size:32px; font-weight:500; height:91px; line-height:90px; margin:0 10px; padding:0 32px; position:relative; }
	.email_sel_popttl .close { background:url("../img/mem/btn_close.png") no-repeat; display:block; height:33px; overflow:hidden; position:absolute; right:44px; text-indent:-20000px; top:29px; width:33px; }

	.email_sel_pop_cont { padding:39px 30px; }
	.email_sel_list ul:after { clear:both; content:""; display:block; }
	.email_sel_list ul { margin:-20px 0 0 -20px; }
	.email_sel_list ul li { float:left; height:92px; margin:20px 0 0 20px; position:relative; width:280px; }
	.email_sel_list ul li input[type="radio"] { height:0; opacity:0; position:absolute; width:0; }
	.email_sel_list ul li label { border:1px solid #666; border-radius:10px; color:#333; display:block; font-size:26px; font-weight:400; height:100%; line-height:90px; padding-left:94px; width:100%; }
	.email_sel_list ul li input[type="radio"]:checked + label { border:2px solid #f0af24; }

	.email_sel_list ul li.naver label { background:url("../img/mem/icon_mail01.png") 28px center no-repeat; }
	.email_sel_list ul li.hanmail label { background:url("../img/mem/icon_mail02.png") 28px center no-repeat; }
	.email_sel_list ul li.nate label { background:url("../img/mem/icon_mail03.png") 28px center no-repeat; }
	.email_sel_list ul li.google label { background:url("../img/mem/icon_mail04.png") 28px center no-repeat; }
	.email_sel_list ul li.private label { padding-left:0; text-align:center; }

	input.recomm_input { -webkit-appearance:none; appearance:none; border:1px solid #c6c6c6; color:#333; font-size:26px; font-weight:400; height:100px; padding:0 28px; width:100%; }

	.memjoin_done { padding:318px 30px 0; }
	.memjoin_done_ttl { background:url("../img/mem/memjoin_done_logo.jpg") center top no-repeat; color:#333; font-size:42px; font-weight:500; line-height:56px; padding-top:250px; text-align:center; }
	.memjoin_done a.cashcow_start { border:1px solid #999; border-radius:42px; color:#333; display:block; font-size:26px; font-weight:400; height:84px; line-height:82px; margin-top:140px; text-align:center; }
	.memjoin_done a.cashcow_start img { margin:29px 0 0 19px; }

	/* 약관 레이어 */
	.policy_layer_bg { background:#fff; height:100%; left:0; position:fixed; top:0; width:100%; z-index:150; }
	.policy_layer_cont { background:#fff; height:100%; left:0; position:absolute; top:0; width:100%; z-index:151; }
	.policy_layer_cont section { color:#333; font-size:26px; font-weight:400; height:100%; line-height:1.4; overflow:auto; -webkit-overflow-scrolling:touch; padding:126px 30px; }
	.policy_layer_cont section iframe { height:100% !important; width:1px !important; min-width:100% !important; *width:100%; }
	.policy_layer_cont section h3 { color:#333; font-size:32px; font-weight:400; }
	.policy_layer_cont section p { color:#333; font-size:26px; font-weight:400; }
	/* 약관 레이어 end */