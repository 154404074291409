@charset "UTF-8";

	.fav_store_slide_wrap { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; margin-top:20px; }
	.fav_store_slide { background:#fff; height:200px; overflow:hidden; padding:40px 0 40px 30px; position:relative; }
	.fav_store_slide li { width:120px; }
	.fav_store_slide li a { display:block; width:120px; }

	.fav_store_slide .swiper-wrapper .swiper-slide { background:#f5f5f5; border-radius:50%; height:120px; margin-right:40px !important; width:120px; }
	.fav_store_slide .swiper-wrapper .swiper-slide a { display:block; height:100%; position:relative; width:100%; }
	.fav_store_slide .swiper-wrapper .swiper-slide a img { mix-blend-mode:multiply; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:100px; }

	.receipt_store_sel { background:#fff; border-bottom:1px solid #c6c6c6; border-top:1px solid #c6c6c6; margin-top:20px; padding-bottom:70px; }

	.store_sep_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:28px; font-weight:400; height:101px; line-height:100px; padding-left:110px; position:relative; }
	/*.store_sep_ttl.mart { background:url("../img/receipt/icon_mart.png") 30px center no-repeat; }
	.store_sep_ttl.store { background:url("../img/receipt/icon_store.png") 30px center no-repeat; }*/

	.store_sep_ttl img { left:30px; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.receipt_store_sel ul li { border-bottom:1px solid #f0f0f0; height:101px; position:relative; }
	.receipt_store_sel ul li.brandLists > div { height:100%; }
	.receipt_store_sel ul li a { background:url("../img/mem/btn_arrow_g.png") right 30px center no-repeat; display:block; height:100%; padding-left:110px; position:relative; width:100%; }
	.receipt_store_sel ul li a > * { float:left; position:relative; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }
	.receipt_store_sel ul li a figure { width:90px; }
	.receipt_store_sel ul li a figure img { height:auto !important; width:100%; }
	.receipt_store_sel ul li a .name { color:#333; font-size:28px; font-weight:400; overflow-x:hidden; padding:0 40px; text-overflow:ellipsis; white-space:nowrap; width:285px; }

	.receipt_store_sel ul li a .recpt_class { height:100%; width:102px; }
	.receipt_store_sel ul li a .recpt_class > p { border:1px solid #c6c6c6; border-radius:17px; color:#666; font-size:18px; font-weight:400; height:34px; left:82px; position:absolute; text-align:center; width:104px; }
	.receipt_store_sel ul li a .recpt_class > p > span { color:#666; display:block; font-size:18px; font-weight:400; left:0; line-height:1; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-51%); transform:translateY(-51%); width:100%; }
	.receipt_store_sel ul li a .recpt_class .up { top:15px; }
	.receipt_store_sel ul li a .recpt_class .down { bottom:15px; }

	.receipt_store_sel ul li .star { background:url("../img/receipt/star_off.png") center no-repeat; height:80px; margin-top:-40px; position:absolute; right:182px; top:50%; width:84px; z-index:5; }
	.receipt_store_sel ul li .star.on { background:url("../img/receipt/star_on.png") center no-repeat; }

	.no_fav_store { color:#666; font-size:28px; font-weight:400; left:0; line-height:42px; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }

	.store_receipt_ttl { text-align:center; }
	.store_receipt_ttl strong { color:#333; display:block; font-size:40px; font-weight:500; margin-top:20px; text-align:center; }

	.store_receipt_ttl.icon { background:url("../img/receipt/icon_accum.png") center top no-repeat; padding-top:280px; }
	.store_receipt_ttl .store_receipt_txt { font-size:0; text-align:center; }
	.store_receipt_ttl .store_receipt_txt img { display:inline-block; height:60px; margin-right:25px; vertical-align:top; width:auto; }
	.store_receipt_ttl .store_receipt_txt strong { display:inline-block; line-height:60px; margin-top:0; vertical-align:top; }

	.store_receipt_ttl.sel_icon { background:url("../img/receipt/icon_accum_sel.png") center top no-repeat; border-bottom:1px solid #c6c6c6; padding:280px 0 90px; }

	.receipt_regi_chklist { background:#f0f0f0; margin:44px 30px 0; padding:20px 68px; }
	.receipt_regi_chklist ul li { color:#333; font-size:24px; font-weight:400; margin-top:14px; padding-left:46px; position:relative; }
	.receipt_regi_chklist ul li:first-child { margin-top:0; }
	.receipt_regi_chklist ul li strong { font-weight:700; }
	.receipt_regi_chklist ul li:before { background:url("../img/receipt/icon_chk_list.png") no-repeat; content:""; height:20px; left:0; position:absolute; top:5px; width:28px; }

	.each_store_warn { border:2px solid #f0f0f0; margin:16px 30px 0; }

	.purch_date_sel { margin-top:45px; }
	.purch_date_sel > strong { color:#333; display:block; font-size:32px; font-weight:400; line-height:1; text-align:center; }
	.purch_date_sel ol { font-size:0; margin-top:43px; text-align:center; }
	.purch_date_sel ol li { display:inline-block; height:92px; position:relative; vertical-align:top; width:221px; }
	.purch_date_sel ol li:nth-child(2) { margin:0 14px; }
	.purch_date_sel ol li input[type="radio"] { -webkit-appearance:none; appearance:none; height:0; left:0; opacity:0; position:absolute; top:0; width:0; }
	.purch_date_sel ol li label { background:#f4f4f4; border:2px solid #666; border-radius:10px; display:block; height:100%; position:relative; width:100%; }
	.purch_date_sel ol li input[type="radio"]:checked + label { background:#f0af24; border:2px solid #f0af24; }
	.purch_date_sel ol li label p { color:#666; font-size:26px; font-weight:400; left:0; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; }
	.purch_date_sel ol li label p strong { color:#333; display:block; font-size:28px; }
	.purch_date_sel ol li input[type="radio"]:checked + label p,
	.purch_date_sel ol li input[type="radio"]:checked + label p strong { color:#fff; }
	.purch_date_sel > p { color:#666; font-size:26px; font-weight:400; line-height:42px; margin:34px 20px 0; padding-left:38px; position:relative; }
	.purch_date_sel > p:before { background:url("../img/mem/icon_time_limit.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:10px; width:28px; }
	.purch_date_sel > p span { color:#dc4630; }

	.receipt_sel_pop { background:#fff; border-radius:30px; height:374px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:640px; z-index:151; }
	.receipt_sel_pop_cont { height:274px; position:relative; }
	.receipt_sel_pop_inner { height:190px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:480px; }
	.receipt_sel_pop_inner:after { clear:both; content:""; display:block; }
	.receipt_sel_pop_inner:before { background:#666; content:""; height:190px; left:50%; position:absolute; top:0; width:1px; }

	.receipt_each { margin-top:15px; width:193px; }
	.receipt_each.elec_receipt { float:left; }
	.receipt_each.paper_receipt { float:right; }

	.receipt_each a { display:block; height:100%; width:100%; }

	.receipt_each .icon_wrap { height:117px; position:relative; width:193px; }

	.receipt_each.elec_receipt .icon_wrap { background:url("../img/receipt/icon_elec_receipt.png") center 11px no-repeat; }
	.receipt_each.paper_receipt .icon_wrap { background:url("../img/receipt/icon_paper_receipt.png") center 11px no-repeat; }

	.regi_deny_cover { background:url("../img/receipt/regi_deny_cover.png") no-repeat; height:117px; left:0; position:absolute; top:0; width:193px; z-index:1; }

	.receipt_each p { color:#333; font-size:28px; font-weight:500; line-height:1; margin-top:27px; text-align:center; }

	.receipt_sel_cancel { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; color:#fff; display:block; font-size:32px; font-weight:500; height:100px; line-height:100px; text-align:center; width:100%; }

	.receipt_shoot_pop { background:#f0f0f0; height:100%; left:0; position:fixed; top:0; width:100%; z-index:151; }
	.receipt_shoot_poptop { background:#fff; border-bottom:1px solid #c6c6c6; color:#333; font-size:34px; font-weight:700; height:96px; line-height:95px; text-align:center; }
	.receipt_shoot_pop ul { margin-top:65px; padding:0 38px; }
	.receipt_shoot_pop ul li { color:#333; font-size:32px; font-weight:400; margin-top:15px; padding-left:14px; position:relative; }
	.receipt_shoot_pop ul li:first-child { margin-top:0; }
	.receipt_shoot_pop ul li:before { background:#333; border-radius:50%; content:""; height:7px; left:0; position:absolute; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:7px; }
	.receipt_shoot_pop ul li strong { font-weight:700; }

	.receipt_slide_wrap { margin-top:30px; padding:0 18px 57px; position:relative; }
	.receipt_stat_btn { height:80px; }
	.receipt_stat_btn:after { clear:both; content:""; display:block; }
	.receipt_stat_btn p { border-radius:8px; font-size:32px; font-weight:500; height:80px; line-height:74px; text-align:center; width:308px; }
	.receipt_stat_btn p.green { border:3px solid #009912; color:#009912; float:left; margin-left:28px; }
	.receipt_stat_btn p.green img { margin:27px 17px 0 0; }
	.receipt_stat_btn p.red { border:3px solid #d6343c; color:#d6343c; float:right; margin-right:28px; }
	.receipt_stat_btn p.red img { margin:25px 17px 0 0; }

	.receipt_shoot_guide { color:#666; font-size:28px; font-weight:400; margin-top:40px; text-align:left; }

	.swiper-pagination .swiper-pagination-fraction,
	.swiper-pagination .swiper-pagination-custom,
	.swiper-pagination .swiper-container-horizontal > .swiper-pagination-bullets { bottom:0; }
	.swiper-pagination .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin:0 5px; }
	.swiper-pagination .swiper-pagination-bullet { background:#fff; height:18px; opacity:1; width:18px; }
	.swiper-pagination .swiper-pagination-bullet-active { background:#f0af24; }

	.receipt_shoot_btm { bottom:0; height:100px; left:0; position:fixed; width:100%; z-index:11; }
	.receipt_shoot_btm:after { clear:both; content:""; display:block; }
	.receipt_shoot_btm a { color:#fff; float:left; font-size:32px; font-weight:500; height:100px; line-height:100px; text-align:center; }
	.receipt_shoot_btm a.btn_nomore { background:#c6c6c6; width:300px; }
	.receipt_shoot_btm a.btn_nomore.on { background:#333; }
	.receipt_shoot_btm a.btn_ok { background:#f0af24; width:450px; }

	.elec_receipt_img { margin-top:30px; padding:0 30px; position:relative; text-align:center; }
	.elec_receipt_img p.elec_receipt_guide { color:#333; font-size:22px; font-weight:500; line-height:32px; position:absolute; right:80px; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); }

	.elec_receipt_ttl { border-bottom:1px solid #c6c6c6; height:275px; margin:0 40px; position:relative; text-align:center; }
	.elec_receipt_ttl strong { display:block; color:#333; font-size:34px; font-weight:500; margin-top:45px; text-align:center; }

	.elec_receipt_cont { margin-top:35px; padding:0 40px; }
	.elec_receipt_cont:after { clear:both; content:""; display:block; }

	.elec_receipt_cont .btn_wrap { float:left; width:157px; }
	.elec_receipt_cont .btn_wrap a { background:url("../img/receipt/btn_all_off.png") no-repeat; color:#666; display:block; font-size:28px; font-weight:500; height:157px; line-height:157px; text-align:center; width:157px; }
	.elec_receipt_cont .btn_wrap a:last-child { margin-top:45px; }
	.elec_receipt_cont .btn_wrap a.on { background:url("../img/receipt/btn_all_on.png") no-repeat; color:#fff; }

	.elec_receipt_cont .scr_wrap { float:right; width:460px; }
	.elec_receipt_cont .scr_wrap ul { margin:-7px 0 0 -8px; }
	.elec_receipt_cont .scr_wrap ul li { background:url("../img/receipt/receipt_bg_off.png") no-repeat; float:left; height:351px; margin:7px 0 0 8px; overflow:hidden; position:relative; width:226px; }
	.elec_receipt_cont .scr_wrap ul li img { left:50%; position:absolute; top:7px; -webkit-transform:translateX(-50%); transform:translateX(-50%); }
	.elec_receipt_cont .scr_wrap ul li i { background:url("../img/receipt/scrshot_chk_off.png") no-repeat; display:block; height:40px; position:absolute; right:22px; top:18px; width:40px; z-index:5; }
	.elec_receipt_cont .scr_wrap ul li.chkd { background:url("../img/receipt/receipt_bg_on.png") no-repeat; }
	.elec_receipt_cont .scr_wrap ul li.chkd i { background:url("../img/receipt/scrshot_chk_on.png") no-repeat; }

	figure.reg_img { text-align:center; }
	figure.reg_img img { box-shadow:0 5px 15px 5px rgba(0, 0, 0, 0.08); width:308px; }

	.elec_receipt_movelist { padding-top:35px; }
	.elec_receipt_movelist li { height:404px; margin-top:27px; position:relative; }
	.elec_receipt_movelist li:first-child { margin-top:0; }
	.elec_receipt_movelist li:after { clear:both; content:""; display:block; }
	.elec_receipt_movelist li:before { background:url("../img/receipt/receipt_move_arrow.png") no-repeat; content:""; height:40px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:56px; }
	.elec_receipt_movelist li p { background:url("../img/receipt/receipt_bg_move.png") no-repeat; height:404px; position:relative; width:260px; }
	.elec_receipt_movelist li p.left { float:left; }
	.elec_receipt_movelist li p.right { float:right; }

	.elec_receipt_movelist a.btn_receipt_del { background:url("../img/receipt/btn_receipt_del.png") no-repeat; display:block; height:44px; overflow:hidden; position:absolute; right:15px; text-indent:-20000px; top:15px; width:44px; z-index:5; }

	.elec_receipt_movelist li p span { color:#666; font-size:65px; font-weight:500; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); z-index:1; }

	.accum_prod_receipt_each { background:#fff; border-bottom:1px solid #c6c6c6; border-top:none; margin-top:0; }

	.accum_prod_receipt_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:700; height:91px; line-height:90px; padding:0 30px; position:relative; }

	.accum_prod_receipt_each li { border-bottom:1px solid #f0f0f0; height:201px; position:relative; }
	.accum_prod_receipt_each li:last-child { border-bottom:none; }
	.accum_prod_receipt_each li a { display:block; height:100%; padding-top:40px; width:100%; }

	.accum_prod_receipt_each li .prod_info { height:90px; padding:0 150px; position:relative; }
	.accum_prod_receipt_each li .prod_info img { left:30px; position:absolute; top:0; }
	.accum_prod_receipt_each li .prod_info .prod_info_txt { color:#333; font-size:28px; font-weight:400; line-height:36px; overflow:hidden; padding-top:10px; text-overflow:ellipsis; white-space:nowrap; }
	.accum_prod_receipt_each li .prod_info .prod_info_txt strong { color:#dc4630; font-weight:700; }
	.accum_prod_receipt_each li .prod_info .prod_stat { background:#fff; border:1px solid #666; border-radius:4px; color:#333; font-size:26px; font-weight:400; height:54px; line-height:52px; position:absolute; right:30px; text-align:center; top:14px; width:78px; }
	.accum_prod_receipt_each li .prod_info .prod_stat.num { background:#f0af24; border:1px solid #f0af24; color:#fff; }
	.accum_prod_receipt_each li .prod_info .prod_stat.scan { background:url("../img/receipt/btn_scan.png") center no-repeat; overflow:hidden; text-indent:-20000px; }

	.accum_prod_receipt_each li .prod_tag { margin-top:5px; padding-left:150px; }
	.accum_prod_receipt_each li .prod_tag p { border:1px solid #c6c6c6; border-radius:17px; color:#666; float:left; font-size:20px; font-weight:400; height:34px; line-height:32px; margin-right:3px; text-align:center; width:102px; }

	.accum_done_cover { height:100%; left:0; position:absolute; top:0; width:100%; z-index:5; }
	.accum_done_dimm { background:#fff; height:100%; left:0; opacity:.8; position:absolute; top:0; width:100%; }
	.accum_done_cover p { border:1px solid #dc4630; color:#dc4630; font-size:38px; font-weight:500; height:auto; left:50%; padding:10px; position:absolute; text-align:center; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:auto; }

	.purch_num_pop { background:#fff; border-radius:30px; left:50%; margin-left:-310px; position:fixed; top:50% !important; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:620px; z-index:151; }
	.purch_num_popttl { border-bottom:1px solid #c6c6c6; color:#333; display:block; font-size:32px; font-weight:500; height:91px; line-height:90px; padding:0 30px; position:relative; }
	.purch_num_popttl a.btn_close { background:url("../img/common/btn_close.png") no-repeat; display:block; height:33px; overflow:hidden; position:absolute; right:34px; text-indent:-20000px; top:29px; width:33px; }

	.purch_num_pop_cont { padding-top:55px; text-align:center; }
	.purch_num_pop_cont strong { color:#333; display:block; font-size:40px; font-weight:500; margin-top:10px; overflow:hidden; padding:0 30px; text-overflow:ellipsis; white-space:nowrap; width:100%; }
	.purch_num_pop_cont p.accum_limit { display:inline-block; font-size:26px; font-weight:400; height:28px; line-height:1; margin-top:20px; padding-left:34px; position:relative; }
	.purch_num_pop_cont p.accum_limit:before { background:url("../img/mem/icon_time_limit.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:2px; width:28px; }

	.purch_num_count { font-size:0; height:64px; margin-top:47px; text-align:center; }
	.purch_num_count > p { display:inline-block; vertical-align:top; }
	.purch_num_count > p a { display:block; height:100%; overflow:hidden; text-indent:-20000px; width:100%; }
	.purch_num_count > p.minus,
	.purch_num_count > p.plus { height:64px; width:64px; }
	.purch_num_count > p.minus a { background:url("../img/receipt/btn_minus.png") no-repeat; }
	.purch_num_count > p.minus a.on { background:url("../img/receipt/btn_minus_on.png") no-repeat; }
	.purch_num_count > p.plus a { background:url("../img/receipt/btn_plus.png") no-repeat; }
	.purch_num_count > p.plus a.on { background:url("../img/receipt/btn_plus_on.png") no-repeat; }
	.purch_num_count > p.count { color:#333; font-size:46px; font-weight:700; line-height:64px; width:152px; }

	.purch_count_ok { background:#333; border-bottom-left-radius:30px; border-bottom-right-radius:30px; color:#fff; display:block; font-size:28px; font-weight:500; height:100px; line-height:100px; margin-top:60px; text-align:center; }

	.barcode_shoot_wrap { background:gold; height:700px; position:relative; }
	.btn_barcode_rec { height:100px; }
	.btn_barcode_rec a { background:url("../img/receipt/btn_barcode_arrow.png") right 30px center no-repeat #333; color:#fff; display:block; font-size:28px; font-weight:400; height:100%; line-height:100px; padding:0 30px; width:100%; }
	.btn_barcode_rec a span { color:#f0af24; }

	.scan_prod_list { background:#fff; border-top:1px solid #c6c6c6; margin-top:20px; }
	.scan_prod_list_ttl { border-bottom:1px solid #f0f0f0; color:#333; display:block; font-size:32px; font-weight:700; height:91px; line-height:90px; padding:0 30px; }

	.scan_prod_list ul li { border-bottom:1px solid #f0f0f0; height:171px; padding:30px 30px 0; position:relative; }
	.scan_prod_list ul li img { float:left; }
	.scan_prod_list ul li p { color:#333; font-size:28px; font-weight:400; line-height:110px; overflow:hidden; padding:0 20px; text-overflow:ellipsis; white-space:nowrap; width:550px; }

	.barcode_dimm { height:280px; left:50%; position:absolute; top:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%); width:460px; z-index:101; }
	.barcode_dimm_bg { background:#000; border-radius:30px; height:100%; opacity:.8; width:100%; }
	.barcode_dimm p { color:#fff; font-size:32px; font-weight:400; left:0; line-height:1; padding-top:130px; position:absolute; text-align:center; top:50%; -webkit-transform:translateY(-50%); transform:translateY(-50%); width:100%; z-index:5;  }
	.barcode_dimm p.no_match { background:url("../img/common/icon_big_x.png") center top no-repeat; }
	.barcode_dimm p.scan_done { background:url("../img/common/icon_chkd.png") center top no-repeat; }

	.barcode_input_wrap { padding:54px 30px; }
	.barcode_input_warn li { color:#333; font-size:26px; font-weight:400; margin-top:15px; padding-left:36px; position:relative; }
	.barcode_input_warn li:first-child { margin-top:0; }
	.barcode_input_warn li:before { background:url("../img/receipt/btn_emphasis_s.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:5px; width:28px; }

	.barcode_input_direct { color:#333; font-size:26px; font-weight:700; line-height:42px; margin:40px 0 20px; text-align:center; }
	.barcode_img { text-align:center; }

	.barcode_num_input { border:1px solid #c6c6c6; color:#333; font-size:32px; font-weight:400; height:100px; margin-top:55px; padding:0 20px; width:100%; }

	.accum_txt_warn { color:#666; font-size:28px; font-weight:500; line-height:40px; margin-top:65px; }
	.accum_txt_warn img { margin:8px 10px 0 0; }
	.accum_txt_warn span { color:#dc4630; }

	.accum_prod_receipt_list.selected { border-top:1px solid #c6c6c6; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li { height:171px; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li a { padding-top:30px; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li .prod_info { height:110px; padding:0 160px; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li .prod_info .prod_info_txt { padding-top:0; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li .prod_info .prod_info_txt strong { font-size:32px; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li .prod_info .prod_stat { top:23px; }
	.accum_prod_receipt_list.selected .accum_prod_receipt_each li .prod_tag { margin-top:-30px; padding-left:160px; }

	.receipt_upload_pop { background:#fff; height:100%; left:0; position:fixed; top:0; width:100%; z-index:101; }
	.receipt_upload_wrap { background:url("../img/receipt/icon_receipt_upload.png") center top no-repeat; height:502px; left:50%; margin:-251px 0 0 -280px; padding-top:250px; position:absolute; top:50%; width:560px; }
	.receipt_upload_wrap p { color:#666; font-size:32px; font-weight:400; line-height:1; text-align:center; }
	.receipt_upload_wrap strong { color:#f0af24; display:block; font-size:42px; font-weight:700; line-height:1; margin-top:135px; text-align:center; }

	.receipt_upload_graph { bottom:0; height:16px; left:0; position:absolute; width:100%; }
	.receipt_upload_bar { background:linear-gradient(90deg, rgba(250,209,99,1) 0%, rgba(241,177,40,1) 100%); border-radius:8px; height:16px; left:0; position:absolute; top:0; z-index:5; }
	.receipt_upload_rail { background:#f0f0f0; border-radius:5px; height:10px; left:0; position:absolute; top:3px; width:100%; }

	.accum_req_done { left:0; position:absolute; top:50%; -webkit-transform:translateY(-60%); transform:translateY(-60%); width:100%; }
	.accum_req_done figure { background:url("../img/receipt/icon_accum_done.png") center top no-repeat; display:block; height:220px; margin:0 auto; position:relative; width:240px; }
	.accum_req_done figure span { bottom:0; color:#f0af24; display:block; font-size:32px; font-weight:700; height:120px; left:0; line-height:120px; position:absolute; text-align:center; width:120px; }
	.accum_req_done strong { color:#333; display:block; font-size:40px; font-weight:500; line-height:1; margin-top:60px; text-align:center; }
	.accum_req_done p { color:#666; font-size:28px; font-weight:400; line-height:1; margin-top:20px; text-align:center; }

	.btn_rew_req_cancel { height:80px; margin-top:60px; }
	.btn_rew_req_cancel a { background:#f0f0f0; border:1px solid #666; border-radius:5px; color:#333; display:block; font-size:28px; font-weight:400; height:100%; line-height:78px; text-align:center; width:100%; }

	.perch_list_cont.accum .point_accum_warn_wrap { margin-top:100px; padding:0 40px 0 20px; }
	.perch_list_cont.accum .point_accum_warn_wrap li { border-bottom:none; color:#666; font-size:26px; font-weight:400; line-height:45px; padding-left:36px; position:relative; }
	.perch_list_cont.accum .point_accum_warn_wrap li:before { background:url("../img/receipt/btn_emphasis_s.png") no-repeat; content:""; height:28px; left:0; position:absolute; top:10px; width:28px; }